import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateUserData } from "../users";
import { errorSideAlert, successSideAlert } from "../alerts";
import { authStoreKey } from "./auth.const";


const authPath = `${process.env.REACT_APP_BACKEND_URL}/auth`;

export const loginUser = createAsyncThunk(
  `${authStoreKey}/loginUser`,
  async (data, thunkApi) => {
    try {
      const response = await axios.post(`${authPath}/login`, data);

      thunkApi.dispatch(updateUserData(response.data.user));

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return error.response.data;
    }
  }
);

export const autoLogin = createAsyncThunk(
  `${authStoreKey}/autoLogin`,
  async (_, thunkApi) => {
    try {
      const response = await axios.get(`${authPath}/auto-login`);

      thunkApi.dispatch(updateUserData(response.data.user));

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const logout = createAsyncThunk(
  `${authStoreKey}/logout`,
  async (_, thunkApi) => {
    try {
      await axios.get(`${authPath}/logout`);

      thunkApi.dispatch(updateUserData(null));
      return;
    } catch (error) {
      return;
    }
  }
);

export const changePassword = createAsyncThunk(
  `${authStoreKey}/changePassword`,
  async (data, thunkApi) => {
    const { passwords, JWT } = data;
    try {
      const response = await axios.post(
        `${authPath}/password-reset`,
        { JWT, passwords }
      );

      thunkApi.dispatch(successSideAlert(response.data.message));

      return true;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return;
    }
  }
);

export const getPasswordRecoveryToken = createAsyncThunk(
  `${authStoreKey}/getPasswordRecoveryToken`,
  async (email, thunkApi) => {
    try {
      const response = await axios.post(`${authPath}/password-recovery`, { email });

      thunkApi.dispatch(successSideAlert(response.data.message || 'Hemos enviado un mail con el código.'));

      return response.data.token;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return;
    }
  }
);

export const verifyPasswordRecoveryCode = createAsyncThunk(
  `${authStoreKey}/verifyPasswordRecoveryCode`,
  async (data, thunkApi) => {
    try {
      const response = await axios.post(`${authPath}/password-recovery-code`, data);

      thunkApi.dispatch(successSideAlert(response.data.message));

      return data.JWT;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return null;
    }
  }
);