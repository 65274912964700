import React from 'react';
import './Spinner.scss';
const Spinner = (props) => {
  return (
    <>
      {props.modal ? (
        <div className="spinner-container">
          <div className="loadingSpinner">
            <div className="spinner"></div>
            {props.text && <p className='mt-2' style={{fontWeight: 500}}>{props.text}</p>}
          </div>
        </div>
      ) : (
        <div className="loadingSpinner">
          <div className="spinner"></div>
          {props.text && <p className='mt-2' style={{fontWeight: 500}}>{props.text}</p>}
        </div>
      )}
    </>
  );
};

export default Spinner;
