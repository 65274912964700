import { React, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import ContentPositioner from './components/ContentPositioner/ContentPositioner';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';
import PasswordRecovery from './pages/Auth/PasswordRecovery/PasswordRecovery.jsx';
import DashboardDisplay from './pages/Dashboard/DashboardDisplay';
import Siniestros from './pages/Siniestros/Siniestros';
import SiniestroDetail from './pages/Siniestros/SiniestroDetail/SiniestroDetail.jsx';
import EditSiniestro from './pages/Siniestros/EditSiniestro/EditSiniestro';
import NotFound404 from './pages/NotFound404';
import Profile from './pages/Profile/Profile.jsx';
import NewUser from './pages/Admin/NewUser/NewUser';
import Polizas from './pages/Polizas/Polizas';
import PolizaDetail from './pages/Polizas/PolizaDetail/PolizaDetail.jsx';
import EditPoliza from './pages/Polizas/EditPoliza/EditPoliza.jsx';
import NewPoliza from './pages/Polizas/NewPoliza/NewPoliza';
import NewSiniestro from './pages/Siniestros/NewSiniestro/NewSiniestro';
import Studies from './pages/Studies/Studies.jsx';
import SupremeRoutes from './routes/SupremeRoutes';
import UsersList from './pages/Admin/UsersList/UsersList.jsx';
import UserDetail from './pages/Admin/UsersList/UserDetail/UserDetail.jsx';
import AdminRoutes from './routes/AdminRoutes';
import PasswordRecoveryCode from './pages/Auth/PasswordRecoveryCode/PasswordRecoveryCode';
import PasswordReset from './pages/Auth/PasswordReset/PasswordReset';
import ClientAndSupremeRoutes from './routes/ClientAndSupremeRoutes.jsx';
import EditStudy from './pages/Studies/EditStudy/EditStudy.jsx';
import NewStudy from './pages/Studies/NewStudy/NewStudy.jsx';
import StudyDetail from './pages/Studies/StudyDetail/StudyDetail.jsx';
import axios from 'axios';
import AuthenticationDisplay from './pages/Auth/Login/AuthenticationDisplay.jsx';
import { useSnackbarController } from './hooks/useSnackbarController.js';
import { useAppDispatch, useAppSelector } from './redux/hooks.js';
import { authSelectors, autoLogin } from './redux/auth';
import { usersSelector } from './redux/users';
import { Stats } from './pages/Stats/Stats.jsx';
import { getInsuranceCompanies, getInsuranceTypes } from './redux/essentials/essentials.thunks.js';
import './Main.scss';

const App = () => {
  useSnackbarController();

  const dispatch = useAppDispatch();

  axios.defaults.withCredentials = true;

  const loadingSession = useAppSelector(authSelectors.isLoadingSession);
  const user = useAppSelector(usersSelector.userData);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    dispatch(autoLogin());
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(getInsuranceCompanies())
      dispatch(getInsuranceTypes())
    }
  }, [user])

  return (
    <>
      <BrowserRouter>
        {user && !isMobile ? <Sidebar /> : false}
        <ContentPositioner loggedIn={!!user} loadingSession={loadingSession}>
          <Routes>
            <Route
              element={
                <PublicRoutes
                  isAllowed={!!user}
                  loadingSession={loadingSession}
                />
              }
            >
              <Route path="/login" element={<AuthenticationDisplay />} />
              <Route path="/password-recovery" element={<PasswordRecovery />} />
              <Route
                path="/password-recovery-code"
                element={<PasswordRecoveryCode />}
              />
              <Route path="/password-reset" element={<PasswordReset />} />
            </Route>

            <Route
              element={
                <PrivateRoutes
                  isAllowed={!!user}
                  loadingSession={loadingSession}
                />
              }
            >
              <Route path="/" element={<DashboardDisplay />} />
              <Route path="/perfil" element={<Profile />} />
              {/* Polizas */}
              <Route path="/polizas" element={<Polizas />} />
              <Route path="/polizas/:pid" element={<PolizaDetail />} />
            </Route>
            <Route
              element={
                <AdminRoutes isAllowed={user} loadingSession={loadingSession} />
              }
            >
              <Route path="/usuarios" element={<UsersList />} />
              <Route path="/usuarios/:uid" element={<UserDetail />} />
            </Route>

            <Route
              element={
                <ClientAndSupremeRoutes
                  isAllowed={user}
                  loadingSession={loadingSession}
                />
              }
            >
              <Route path="/siniestros" element={<Siniestros />} />
              <Route path="/siniestros/:sid" element={<SiniestroDetail />} />
              <Route
                path="/siniestros/editar/:sid"
                element={<EditSiniestro />}
              />
              <Route path="/siniestros/reportar" element={<NewSiniestro />} />
            </Route>

            <Route
              element={
                <SupremeRoutes
                  isAllowed={user}
                  loadingSession={loadingSession}
                />
              }
            >
              <Route path="/nuevo-usuario" element={<NewUser />} />
              {/* Polizas */}
              <Route path="/polizas/cargar" element={<NewPoliza />} />
              <Route path="/polizas/editar/:pid" element={<EditPoliza />} />
              {/* Medicina Laboral */}
              <Route path="/medicina-laboral" element={<Studies />} />
              <Route path="/medicina-laboral/cargar" element={<NewStudy />} />
              <Route
                path="/medicina-laboral/editar/:studyId"
                element={<EditStudy />}
              />
              <Route
                path="/medicina-laboral/:studyId"
                element={<StudyDetail />}
              />
              <Route
                path="/estadisticas"
                element={<Stats />}
              />
            </Route>
            <Route path="*" element={<NotFound404 user={user} />} />
          </Routes>
        </ContentPositioner>
      </BrowserRouter>
    </>
  );
};

export default App;
