import React from 'react';

const RadioInputSelector = (props) => {
  return (
    <>
      <p className="text-center mb-0 mt-4">{props.title || ''}</p>
      <div className="form-check d-flex justify-content-center gap-5">
        <div className="d-flex gap-2 align-items-center">
          <input
            name={props.name}
            className="form-check-input"
            type="radio"
            checked={props.state ? true : false}
            onChange={() => props.setState(true)}
          />

          <label className="form-check-label">{props.confirm || 'Sí'}</label>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <input
            name={props.name}
            className="form-check-input"
            type="radio"
            checked={!props.state ? true : false}
            onChange={() => props.setState(false)}
          />
          <label className="form-check-label">{props.deny || 'No'}</label>
        </div>
      </div>
    </>
  );
};

export default RadioInputSelector;
