import React from 'react';

const TypeSelector = (props) => {
  return (
    <>
      <label>
        ¿Qué tipo de siniestro es? <span className="requiredFormField">*</span>
      </label>
      <select
        onChange={props.handleOnChangeSiniestro}
        name="tipoSiniestro"
        className="form-select"
        value={props.tipoSiniestro}
        required
      >
        <option value="Robo total">Robo total</option>
        <option value="Robo parcial">Robo parcial</option>
        <option value="Destruccion total">Destrucción total</option>
        <option value="Daño parcial">Daño parcial</option>
        <option value="Robo a cubiertas">Robo de cubiertas</option>
        <option value="Incendio total">Incendio total</option>
        <option value="Incendio parcial">Incendio parcial</option>
        <option value="Granizo">Granizo</option>
        <option value="Cristales">Cristales</option>
      </select>
    </>
  );
};

export default TypeSelector;
