import axios from 'axios';
import {
  createAsyncThunk
} from '@reduxjs/toolkit';
import {
  errorSideAlert,
  successSideAlert
} from '../alerts';
import { siniestrosStoreKey } from '.';

const siniestrosPath = `${process.env.REACT_APP_BACKEND_URL}/siniestros`

export const createSiniestro = createAsyncThunk(
  `${siniestrosStoreKey}/createSiniestro`,
  async (formData, thunkApi) => {
    try {
      const response = await axios.post(`${siniestrosPath}/create`, formData);

      thunkApi.dispatch(successSideAlert("Siniestro cargado exitosamente!"));

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return null;
    }
  }
);

  export const getSiniestros = createAsyncThunk(
    `${siniestrosStoreKey}/getSiniestros`,
    async (query, thunkApi) => {
      try {
        const response = await axios.get(siniestrosPath + query);

        return response.data;
      } catch (error) {
        return thunkApi.dispatch(errorSideAlert(error.response.data.message));
      }
    }
  );

  export const getSiniestroDetail = createAsyncThunk(
    `${siniestrosStoreKey}/getSiniestroDetail`,
    async (siniestroId, thunkApi) => {
      try {
        const response = await axios.get(`${siniestrosPath}/id/${siniestroId}`);

        return response.data;
      } catch (error) {
        thunkApi.dispatch(errorSideAlert(error.response.data.message));
        return null;
      }
    }
  );

  export const updateSiniestro = createAsyncThunk(
    `${siniestrosStoreKey}/updateSiniestro`,
    async ({ sid, formData }, thunkApi) => {
      try {
        const response = await axios.put(`${siniestrosPath}/id/${sid}`, formData);

        thunkApi.dispatch(successSideAlert("Siniestro actualizado exitosamente!"));
        return response.data;
      } catch (error) {
        thunkApi.dispatch(errorSideAlert(error.response.data.message));
        return null;
      }
    }
  );