import axios from 'axios';
import {
  createAsyncThunk
} from '@reduxjs/toolkit';
import {
  errorSideAlert,
  successSideAlert
} from '../alerts';
import { usersStoreKey } from '.';

const usersPath = `${process.env.REACT_APP_BACKEND_URL}/users`

export const updateUser = createAsyncThunk(
  `${usersStoreKey}/updateUser`,
  async (data, thunkApi) => {
    try {
      const response = await axios.put(`${usersPath}/id/${data.userId}`, data);

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert('Error actualizando usuario. Por favor, intenta nuevamente.'));
      return null;
    }
  }
);

export const getUserById = createAsyncThunk(
  `${usersStoreKey}/getUserById`,
  async (id, thunkApi) => {
    try {
      const response = await axios.get(`${usersPath}/id/${id}`);

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert('Error obteniendo usuario. Por favor, intenta nuevamente.'));
      return null;
    }
  }
);

export const editUserById = createAsyncThunk(
  `${usersStoreKey}/editUserById`,
  async (data, thunkApi) => {
    try {
      const response = await axios.put(`${usersPath}/id/${data._id}`, data);

      thunkApi.dispatch(successSideAlert('Usuario actualizado correctamente.'))

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert('Ocurrió un error actualizando el usuario. Por favor, intenta nuevamente.'));
      return null;
    }
  }
);

export const getUsersByRole = createAsyncThunk(
  `${usersStoreKey}/getUsersByRole`,
  async (roles, thunkApi) => {
    roles.join(',');
    try {
      const response = await axios.get(`${usersPath}/by-role/${roles}`);

      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const createNewUser = createAsyncThunk(
  `${usersStoreKey}/createNewUser`,
  async (data, thunkApi) => {
    try {
      const response = await axios.post(`${usersPath}/create`, data);

      thunkApi.dispatch(successSideAlert('Usuario creado correctamente.'));

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return null;
    }
  }
);

export const getUsers = createAsyncThunk(
  `${usersStoreKey}/getUsers`,
  async (queryString, thunkApi) => {
    try {
      const response = await axios.get(`${usersPath}${queryString}`);

      return response.data;
    } catch (error) {
      thunkApi.dispatch('Error buscando usuarios. Por favor, intenta nuevamente.')
      return null;
    }
  }
);

export const getUserByDocument = createAsyncThunk(
  `${usersStoreKey}/getUserByDocument`,
  async ({ documentType, documentNumber }, thunkApi) => {
    try {
      const response = await axios.get(`${usersPath}/by-document?documentType=${documentType}&documentNumber=${documentNumber}`);

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert('Error buscando usuario. Por favor, intenta nuevamente.'))
      return null;
    }
  }
);

export const downloadUsersReport = createAsyncThunk(
  `${usersStoreKey}/downloadUsersReport`,
  async (_, thunkApi) => {
    try {
      const response = await axios.get(`${usersPath}/report`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'clientes.xlsx';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      thunkApi.dispatch('Error descargando reporte de usuarios. Por favor, intenta nuevamente.')
      return null;
    }
  }
);