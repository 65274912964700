import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Spinner from '../../../../components/Spinner/Spinner';
import { formatearFecha } from '../../../../utils/date';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { warningSideAlert } from '../../../../redux/alerts';
import { getPolizas } from '../../../../redux/polizas';
import { essentialsSelectors } from '../../../../redux/essentials';

export const ExistingPolizaSearcher = ({ handleConfirmPoliza }) => {
  const dispatch = useAppDispatch();

  const insuranceCompanies = useAppSelector(essentialsSelectors.insuranceCompanies);

  const [polizaSearcher, setPolizaSearcher] = useState({
    insuranceCompany: '',
    polizaNumber: '',
  });
  const [searching, setSearching] = useState(false);
  const [polizaFound, setPolizaFound] = useState(false);

  const handleOnSubmitSearchPoliza = async (e) => {
    e.preventDefault();
    if (!polizaSearcher.polizaNumber && !polizaSearcher.insuranceCompany)
      return dispatch(warningSideAlert('Por favor complete los campos requeridos'));
    setSearching(true);
    const query = `?insuranceCompany=${polizaSearcher.insuranceCompany}&polizaNumber=${polizaSearcher.polizaNumber}`;
    const res = await dispatch(getPolizas(query)).unwrap();
    if (!res) return;
    if (!res.docs.length)
      return dispatch(warningSideAlert('No se ha encontrado la póliza, asegurate que coincida la compañía y el número'));
    setPolizaFound(res.docs[0]);
    setSearching(false);
  };

  const handleOnChangeSearcher = (e) => {
    setPolizaSearcher({
      ...polizaSearcher,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (polizaFound) return showSwal();
  }, [polizaFound]);

  const showSwal = () => {
    withReactContent(Swal).fire({
      title: '¿Es esta la póliza que buscas renovar?',
      html: (
        <div style={{ overflow: 'hidden', height: '100%', paddingTop: '10px' }}>
          {polizaFound?.anulled ? (
            <span className="chip-danger">
              Esta póliza se encuentra anulada
            </span>
          ) : (
            false
          )}
          <p className="mt-4">
            <b>A nombre de:</b>{' '}
            <a target="_blank" href={`/usuarios/${polizaFound?.userId?._id}`}>
              {polizaFound?.userId?.socialReason || ''}
            </a>
          </p>
          <p>
            <b>Tipo de póliza:</b> {polizaFound?.polizaType || ''}
          </p>
          <p>
            <b>Compañía de seguros:</b> {polizaFound?.insuranceCompany || ''}
          </p>
          {polizaFound?.polizaType === 'Flota' && (
            <p>
              <b>Vehículos de flota:</b> {polizaFound?.float.length || 0}
            </p>
          )}
          <p>
            <b>Endosos:</b> {polizaFound?.endosos?.length || 0}
          </p>
          <p>
            <b>Póliza Nº:</b> {polizaFound?.polizaNumber || ''}
          </p>
          <p style={{ color: 'red' }}>
            <b>Vence el:</b>{' '}
            {polizaFound?.expiresAt
              ? formatearFecha(new Date(polizaFound.expiresAt))
              : ''}
          </p>
        </div>
      ),
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'green',
      preConfirm: () => handleConfirmPoliza(polizaFound),
    });
  };

  return (
    <>
      <form
        onSubmit={handleOnSubmitSearchPoliza}
        className="newSiniestroForm  mt-3"
      >
        <h5 className="text-center mt-2 mb-2">Buscá la póliza existente</h5>
        <label style={{ width: '300px' }}>
          Compañia <span className="requiredFormField">*</span>
        </label>
        <select
          style={{ width: '300px' }}
          name="insuranceCompany"
          onChange={handleOnChangeSearcher}
          className="form-select"
          value={polizaSearcher.insuranceCompany}
          required
        >
          <option value="">Seleccione una compañía de seguros</option>
          {insuranceCompanies.map((insuranceC, index) => {
            return (
              <option key={`insuranceC${index}`} value={`${insuranceC.value}`}>
                {insuranceC.label}
              </option>
            );
          })}
        </select>
        <label style={{ width: '300px' }}>
          Nº de Póliza <span className="requiredFormField">*</span>
        </label>
        <input
          className="form-control"
          name="polizaNumber"
          placeholder="Ingresá el Nº de póliza"
          onChange={(e) => handleOnChangeSearcher(e)}
          value={polizaSearcher.polizaNumber}
          type="text"
          required
        />
        <button type="submit" className="btn btn-info mt-1 mb-2">
          Buscar Póliza
        </button>
        {searching && <Spinner />}
      </form>
    </>
  );
};
