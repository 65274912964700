import React from 'react';
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from 'react-icons/fa';
import './Paginator.scss';
const Paginator = (props) => {
  const { hasPrevPage, page, hasNextPage, onPrevPage, onNextPage, totalPages } =
    props.pages;

  return (
    <div className="paginatorWrapper">
      <div className="paginatorButtons">
        <FaAngleDoubleLeft
          style={
            hasPrevPage
              ? { cursor: 'pointer', marginRight: '10px' }
              : { marginRight: '10px' }
          }
          color={`${hasPrevPage ? '#084fb7' : 'rgb(170,170,170)'}`}
          onClick={() => (hasPrevPage ? onPrevPage(1) : false)}
        />
        <FaAngleLeft
          style={hasPrevPage ? { cursor: 'pointer' } : {}}
          color={`${hasPrevPage ? '#0d6efd' : 'rgb(170,170,170)'}`}
          onClick={() => (hasPrevPage ? onPrevPage() : false)}
        />
        <p>{page || 1}</p>
        <FaAngleRight
          style={hasNextPage ? { cursor: 'pointer' } : {}}
          color={`${hasNextPage ? '#0d6efd' : 'rgb(170,170,170)'}`}
          onClick={() => (hasNextPage ? onNextPage() : false)}
        />
        <FaAngleDoubleRight
          style={
            hasNextPage
              ? { cursor: 'pointer', marginLeft: '10px' }
              : { marginLeft: '10px' }
          }
          color={`${hasNextPage ? '#084fb7' : 'rgb(170,170,170)'}`}
          onClick={() => (hasNextPage ? onNextPage(totalPages) : false)}
        />
      </div>
    </div>
  );
};

export default Paginator;
