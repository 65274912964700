import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Spinner from '../../../components/Spinner/Spinner';
import { useAppDispatch } from '../../../redux/hooks';
import { verifyPasswordRecoveryCode } from '../../../redux/auth';
import '../Auth.scss';

const PasswordRecoveryCode = () => {
  const dispatch = useAppDispatch();

  const [code, setCode] = useState('');
  const [JWT, setJWT] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const jwt = await dispatch(verifyPasswordRecoveryCode({ JWT, code })).unwrap();
    navigate(`/password-reset?token=${jwt}`);
    setLoading(false);
  };
  const handleOnChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, '');
    if (inputValue.length <= 6) {
      setCode(inputValue);
    }
  };

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  useEffect(() => {
    setJWT(token);
  }, [token]);
  return (
    <>
      {loading ? <Spinner modal={true} /> : false}
      <div className="formContainer">
        <img src="/images/logo.png" alt="Logo" />
        <form onSubmit={handleOnSubmit}>
          <h2>Email enviado</h2>
          <label>Ingresa el código de verificación</label>
          <input
            type="text"
            value={code}
            onChange={handleOnChange}
            maxLength={6}
          />
          <button type="submit" className="btn btn-danger">
            Validar Código
          </button>
        </form>
        <Link to="/login">
        <p className="text-center mt-2" style={{textDecoration: 'underline'}}>Iniciar sesión</p>
        </Link>
      </div>
    </>
  );
};

export default PasswordRecoveryCode;
