import { createSlice } from "@reduxjs/toolkit";
import { getStudies, studiesStoreKey } from ".";

const initialState = {
  isLoading: false,
  studiesData: null,
};

export const studiesSlice = createSlice({
  name: studiesStoreKey,
  initialState,
  reducers: {
  },

  extraReducers(builder) {
    // getStudies
    builder.addCase(getStudies.pending, (state) => {
      state.isLoading = true;
      state.studiesData = null;
    });
    builder.addCase(getStudies.rejected, (state) => {
      state.isLoading = false;
      state.studiesData = null;
    });
    builder.addCase(getStudies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.studiesData = action.payload;
    });
  }
});

// export const { v } = getStudiesSlice.actions;
