import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../../../components/Spinner/Spinner';
import { useAppDispatch } from '../../../redux/hooks';
import '../Auth.scss';
import { getPasswordRecoveryToken } from '../../../redux/auth';

const PasswordRecovery = () => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = await dispatch(getPasswordRecoveryToken(email)).unwrap();
    navigate(`/password-recovery-code?token=${token}`);
    setLoading(false);
  };
  return (
    <>
      {loading ? <Spinner modal={true} /> : false}
      <div className="formContainer">
        <img src="/images/logo.png" />
        <form onSubmit={handleOnSubmit}>
          <h2>RECUPERÁ TU CONTRASEÑA</h2>
          <label>Email</label>
          <input
            name="email"
            onChange={handleOnChange}
            type="email"
            value={email}
          />
          <button type="submit" className="btn btn-danger">
            Recuperar contraseña
          </button>
        </form>
        <Link to='/login'>
        <p className="text-center mt-2" style={{textDecoration: 'underline'}}>Iniciar sesión</p>
        </Link>
      </div>
    </>
  );
};

export default PasswordRecovery;
