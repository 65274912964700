import React, { useContext, useState } from 'react';
import { FaSignOutAlt, FaBars } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import './Sidebar.scss';
import {
  clientSidebar,
  adminSidebar,
  supremeSidebar,
  clientEnterpiseSidebar,
} from '../../constants/sidebarOptions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { usersSelector } from '../../redux/users';
import { logout } from '../../redux/auth';

const Sidebar = ({ children }) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(usersSelector.userData);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const userType = {
    client: clientSidebar,
    clientEnterprise: clientEnterpiseSidebar,
    admin: adminSidebar,
    supreme: supremeSidebar,
  };

  const handleLogout = () => {
    dispatch(logout());
  }

  const sidebarOptions = userType[user.role];

  const currentPath = location.pathname;

  const noSidebarRoutes = [
    '/login',
    '/password-recovery',
    '/password-recovery-code',
    '/password-reset',
  ];

  const isInNoSidebarRoutes = noSidebarRoutes.some((route) =>
    currentPath.includes(route),
  );

  if (isInNoSidebarRoutes) {
    return <div className="contentWithoutSidebar">{children}</div>;
  } else {
    return (
      <>
        <div className="sidebarContainer">
          <div style={{ width: isOpen ? '250px' : '50px' }} className="sidebar">
            <div className="sidebarTopSection">
              <img
                style={{ display: isOpen ? 'block' : 'none' }}
                className="logo"
                src="/images/logo.png"
              />
              <div className="bars">
                <FaBars onClick={toggle} fill="black" />
              </div>
            </div>
            <div className="sidebarOptionsSeparator">
              <div>
                {user
                  ? sidebarOptions.map((item, index) => (
                    <NavLink
                      to={item.path}
                      key={index}
                      className={({ isActive }) =>
                        isActive
                          ? 'sidebarLink activeSidebarItem'
                          : 'sidebarLink'
                      }
                    >
                      <div className="sidebarIcon">{item.icon}</div>
                      <div
                        style={{ display: isOpen ? 'block' : 'none' }}
                        className="sidebarLinkText"
                      >
                        {item.name}
                      </div>
                    </NavLink>
                  ))
                  : false}
              </div>
              <div className="logoutOption" onClick={handleLogout}>
                <span className="sidebarLink">
                  <div className="sidebarIcon">
                    <FaSignOutAlt />
                  </div>
                  <div
                    style={{ display: isOpen ? 'block' : 'none' }}
                    className="sidebarLinkText"
                  >
                    Cerrar sesión
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Sidebar;
