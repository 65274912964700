import styles from '../ReportSiniestro.module.css';

const ThirdDriving = (props) => {
  const handleOnChange = (e) => {
    props.handleOnChangeConductor(e);
  };

  return (
    <>
      <h5 className="text-center mb-0 mt-4">Datos del conductor</h5>
      <label>Nombre completo de quién conducía el vehículo</label>
      <input
        placeholder="Nombre Completo"
        className="form-control"
        name="socialReason"
        onChange={handleOnChange}
        type="text"
        required
      />
      <div className={styles.doubleInputWrapper}>
        <div className={styles.labelAndInputWrapper}>
          <label>Tipo de documento del conductor</label>
          <select
            className="form-select"
            name="documentType"
            onChange={handleOnChange}
            required
          >
            <option value="" hidden>
              Tipo de documento
            </option>
            <option value="CUIT">CUIT</option>
            <option value="DNI">DNI</option>
          </select>
          </div>
          <div className={styles.labelAndInputWrapper}>
          <label>Número de documento</label>
          <input
            placeholder="Número de documento"
            className="form-control"
            name="documentNumber"
            onChange={handleOnChange}
            type="number"
            required
          />
        </div>
      </div>
      <label className="mt-3">Subir archivos del conductor</label>
      <input
        className="form-control"
        type="file"
        name="driverFiles"
        multiple
        required
      />
      <div className="form-text">
        Cargue archivos relacionados con el conductor, como su licencia, DNI,
        etc.
      </div>
    </>
  );
};

export default ThirdDriving;
