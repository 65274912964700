import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Chip } from '../../../../../components/Chip/Chip';

const UserEnterprises = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        marginTop: '10px',
        gap: '15px',
        marginBottom: '20px',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {props.userEnterprises.map((enterprise, index) => {
        return (
          <div
            key={`userEnterprise${index}`}
            className="card text-center"
            style={{ width: '280px' }}
          >
            <div className="card-body">
              <div className="infoAboutPolizaMiniCard">
                <p style={{ fontSize: '18px' }} className="card-title mb-0">
                  <b>Razón social:</b> {enterprise.socialReason}
                </p>
                <p style={{ fontSize: '18px' }} className="card-title mb-1">
                  <b>{enterprise.document.documentType}:</b>{' '}
                  {enterprise.document.documentNumber}
                </p>
              </div>
              <div className="infoAboutPolizaMiniCardButtons">
                <div className="chipsWrapperCentered">
                  {!enterprise.status && (
                    <Chip color="danger" label="Usuario deshabilitado" />
                  )}
                </div>
                <Link
                  to={`/usuarios/${enterprise._id}`}
                  className="btn btn-primary"
                >
                  Ver usuario
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserEnterprises;
