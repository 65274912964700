import React, { useState, useEffect } from 'react';

const RadioInputSelector = (props) => {
  const [isChecked, setIsChecked] = useState(props.defaultValue || false);

  useEffect(() => {
    setIsChecked(props.defaultValue || false);
  }, [props.defaultValue]);

  const handleRadioChange = (value) => {
    setIsChecked(value);
    props.setState(value);
  };

  return (
    <>
      <p className="text-center mb-0 mt-4">{props.title}</p>
      <div className="form-check d-flex justify-content-center gap-5">
        <div
          className={`d-flex gap-2 align-items-center ${isChecked ? 'selected' : ''}`}
        >
          <input
            name={props.name}
            className="form-check-input"
            type="radio"
            value=""
            onChange={() => handleRadioChange(true)}
            checked={isChecked}
          />
          <label className="form-check-label">Sí</label>
        </div>
        <div
          className={`d-flex gap-2 align-items-center ${!isChecked ? 'selected' : ''}`}
        >
          <input
            name={props.name}
            className="form-check-input"
            type="radio"
            value=""
            onChange={() => handleRadioChange(false)}
            checked={!isChecked}
          />
          <label className="form-check-label">No</label>
        </div>
      </div>
    </>
  );
};

export default RadioInputSelector;
