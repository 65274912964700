import React from 'react';
import { Link } from 'react-router-dom';
const DashboardOptions = (props) => {
  return (
    <div className="row row-cols-1 row-cols-md-2 pl-0 ">
      <div className="col ">
        <div className="card ">
          <div className="card-body">
            <div>
              <h5 className="card-title text-center">Pólizas</h5>
            </div>
            <div className="d-flex flex-column gap-2 ">
              <Link to="/polizas" className="btn btn-info fw-semibold">
                Ver mis polizas ({props.user.polizas.length})
              </Link>
              <Link
                to="mailto:contacto@sya-seguros.com.ar"
                className="btn btn-success fw-semibold"
              >
                Contáctanos para cotizar tu nueva póliza
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col ">
        <div className="card ">
          <div className="card-body">
            <div>
              <h5 className="card-title text-center">Siniestros</h5>
            </div>
            <div className="d-flex flex-column gap-2">
              <Link to="/siniestros" className="btn btn-warning fw-semibold">
                Ver mis siniestros ({props.user.siniestros.length})
              </Link>
              <Link
                to="/siniestros/reportar"
                className="btn btn-danger fw-semibold"
              >
                Denunciar siniestro
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardOptions;
