import React from 'react';
import styles from '../../NewSiniestro/ReportSiniestro.module.css';

const ThirdDriving = (props) => {
  const handleOnChange = (e) => {
    props.handleOnChangeConductor(e);
  };
  const handleRemove = (e) => {
    props.handleRemoveConductor(e);
  };
  return (
    <>
      <h5 className="text-center mb-0 mt-4">Datos del conductor</h5>
      <label>Nombre completo de quién conducía el vehículo</label>
      <input
        placeholder="Nombre Completo"
        className="form-control"
        name="socialReason"
        onChange={handleOnChange}
        type="text"
        value={props.conductor.socialReason}
        required
      />
      <div className={styles.doubleInputWrapper}>
        <div className={styles.labelAndInputWrapper}>
          <label>Tipo de documento del conductor</label>
          <select
            className="form-select"
            name="documentType"
            onChange={handleOnChange}
            value={props.conductor.document.documentType}
            required
          >
            <option value="" disabled hidden>
              Tipo de documento
            </option>
            <option value="CUIT">CUIT</option>
            <option value="DNI">DNI</option>
          </select>
        </div>
        <div className={styles.labelAndInputWrapper}>
          <label>Número de documento</label>
          <input
            placeholder="Número de documento"
            className="form-control"
            name="documentNumber"
            onChange={handleOnChange}
            type="number"
            value={props.conductor.document.documentNumber}
            required
          />
        </div>
      </div>
      <label>Subir archivos del conductor</label>
      <input
        className="form-control"
        type="file"
        name="driverFiles"
        multiple
        required={props.conductor.driverFiles?.length === 0}
      />
      <div className="form-text">
        Cargue archivos relacionados con el conductor, como su licencia, DNI,
        etc.
      </div>
    </>
  );
};

export default ThirdDriving;
