import { Link } from 'react-router-dom';
import { formatearFecha } from '../../../../../utils/date';
import { roles } from '../../../../../constants';
import { expiresInOneWeek, isExpired } from '../../../../../utils/isExpired';
import { Chip } from '../../../../../components/Chip/Chip';
import { useAppSelector } from '../../../../../redux/hooks';
import { usersSelector } from '../../../../../redux/users';

const UserPolizas = (props) => {
  const user = useAppSelector(usersSelector.userData);

  return (
    <div
      style={{
        display: 'flex',
        marginTop: '10px',
        gap: '15px',
        marginBottom: '20px',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {props.userPolizas.map((poliza, index) => {
        const fechaVencimiento = poliza.expiresAt
          ? new Date(poliza.expiresAt)
          : false;
        const fechaVencimientoFormateado = fechaVencimiento
          ? formatearFecha(fechaVencimiento)
          : false;
        return (
          <div
            key={`polizaUser${index}`}
            className="card text-center"
            style={{ width: '280px' }}
          >
            <div className="card-body">
              <div className="infoAboutPolizaMiniCard">
                <p style={{ fontSize: '18px' }} className="card-title mb-0">
                  <b>Póliza Nº:</b> {poliza.polizaNumber}
                </p>
                <p style={{ fontSize: '18px' }} className="card-title mb-1">
                  <b>Tipo:</b> {poliza.polizaType}
                </p>
                {poliza.polizaType === 'Automotor' || poliza.polizaType === 'Motovehículo'
                  ? <p style={{ fontSize: '18px' }} className="card-title mb-1">
                    <b>Dominio:</b> {poliza.domain}
                  </p>
                  : false}
              </div>
              <div className="infoAboutPolizaMiniCardButtons">
                <div className="chipsWrapperCentered">
                  {user.role === roles.supreme &&
                    expiresInOneWeek(poliza.expiresAt) ? (
                    <Chip color="purple" label="Vence pronto" />
                  ) :
                    isExpired(poliza.expiresAt) && (
                      <Chip color="warning" label="Vencida" />
                    )
                  }
                  {poliza.anulled &&
                    <Chip color="danger" label="Anulada" />
                  }
                  {poliza.renewed &&
                    <Chip color="primary" label="Renovada" />
                  }
                </div>
                <Link to={`/polizas/${poliza._id}`} className="btn btn-success">
                  Ver póliza
                </Link>
                {user.role === roles.supreme &&
                  (isExpired(poliza.expiresAt) || expiresInOneWeek(poliza.expiresAt)) &&
                  !poliza.anulled &&
                  !poliza.renewed && (
                    <Link
                      to="/polizas/cargar"
                      state={{
                        userDetail: props.userDetail,
                        oldPoliza: poliza,
                      }}
                      className="btn btn-primary"
                    >
                      Renovar
                    </Link>
                  )}
              </div>
            </div>
            <div className="card-footer text-body-secondary">
              <p className="mb-0 text-center">
                <b>
                  {isExpired(poliza.expiresAt) ? 'Vencida el:' : 'Vence el:'}
                </b>
              </p>
              <p className="mb-0 text-center">{fechaVencimientoFormateado}</p>
            </div>
          </div>
        );
      })}
    </div >
  );
};

export default UserPolizas;
