import { Link, useSearchParams } from 'react-router-dom'
import { StatsDisplay } from './StatsDisplay';
import './Stats.css';

export const Stats = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab')

  const switchTab = (value) => {
    setSearchParams({ tab: value });
  }

  return (
    <>
      <h1>Estadísticas</h1>
      <div className="contentContainer card">
        <ul className="nav nav-tabs mt-2" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <li className="nav-item">
            <Link className={tab === 'usuarios' ? 'nav-link active' : 'nav-link'} to="?tab=usuarios" onClick={() => switchTab('usuarios')}>Usuarios</Link>
          </li>
          <li className="nav-item">
            <Link className={tab === 'polizas' ? 'nav-link active' : 'nav-link'} to="?tab=polizas" onClick={() => switchTab('polizas')}>Pólizas</Link>
          </li>
          <li className="nav-item">
            <Link className={tab === 'polizas-por-tipo' ? 'nav-link active' : 'nav-link'} to="?tab=polizas-por-tipo" onClick={() => switchTab('polizas-por-tipo')}>Polizas por tipo</Link>
          </li>
          <li className="nav-item">
            <Link className={tab === 'polizas-por-compania' ? 'nav-link active' : 'nav-link'} to="?tab=polizas-por-compania" onClick={() => switchTab('polizas-por-compania')}>Polizas por compañía</Link>
          </li>
          {/* <li className="nav-item">
            <Link className={tab === 'usuarios-polizas' ? 'nav-link active' : 'nav-link'} to="?tab=usuarios-polizas" onClick={() => switchTab('siniestros')}>Usuarios - Pólizas</Link>
          </li> */}
        </ul>
        <StatsDisplay tab={tab} />
      </div>
    </>
  )
}
