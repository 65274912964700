import React, { useContext } from 'react';
import MainDashboard from './DashboardComponents/MainDashboard';
import './DashboardDisplay.scss';
import { useAppSelector } from '../../redux/hooks';
import { usersSelector } from '../../redux/users';

const DashboardDisplay = () => {
  const user = useAppSelector(usersSelector.userData);
  return (
    <>
      <h1 className="text-center">S&A Seguros</h1>
      <div className="contentContainer">
        <MainDashboard user={user} />
      </div>
    </>
  );
};

export default DashboardDisplay;
