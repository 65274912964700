import React, { useEffect, useState } from 'react';

import { NewPolizaForm } from './components/NewPolizaForm';
import RadioInputSelector from '../../../components/RadioInputSelector/RadioInputSelector';
import { ExistingPolizaSearcher } from './components/ExistingPolizaSearcher';
import { useLocation } from 'react-router-dom';

const NewPoliza = () => {
  const location = useLocation();
  const userDetail = location.state?.userDetail;
  const userPolizaToRenew = location.state?.oldPoliza;

  const [isNewPoliza, setIsNewPoliza] = useState();
  const [existingPoliza, setExistingPoliza] = useState(false);
  const [showRenewalForm, setShowRenewalForm] = useState(false);

  const handleIsNewPoliza = (state) => {
    setIsNewPoliza(state);
    setShowRenewalForm(false);
  };

  const handleShowRenewalForm = (confirmedPoliza) => {
    setShowRenewalForm(true);
    setExistingPoliza(confirmedPoliza);
  };

  useEffect(() => {
    if (userPolizaToRenew && userDetail) {
      setIsNewPoliza(true);
      setShowRenewalForm(true);
      setExistingPoliza(userPolizaToRenew);
    }
  }, []);

  return (
    <>
      <h1>Cargar Póliza</h1>
      <div className="contentContainer">
        <RadioInputSelector
          title={`¿Cargarás la renovación de una póliza existente?`}
          setState={handleIsNewPoliza}
          state={isNewPoliza}
          required
        />
        <div className="form-text text-center" style={{ width: '300px' }}>
          Si vas a cargar una póliza que representa la renovación de una ya
          cargada en el sistema, te recomendamos seleccionar la opción{' '}
          <b>"Sí"</b>
        </div>
        {isNewPoliza ? (
          showRenewalForm && existingPoliza ? (
            <NewPolizaForm
              existingPoliza={existingPoliza}
              userDataFromRedirection={userDetail}
            />
          ) : (
            <ExistingPolizaSearcher
              handleConfirmPoliza={handleShowRenewalForm}
            />
          )
        ) : (
          <NewPolizaForm userDataFromRedirection={userDetail} />
        )}
      </div>
    </>
  );
};

export default NewPoliza;
