import React from 'react';
import { FaCircle } from 'react-icons/fa';

export const Chip = ({ color = 'success', label, sx = {} }) => {
  return (
    <span style={sx} className={`chip__${color}`}>
      <FaCircle size="0.5rem" /> {label}
    </span>
  );
};
