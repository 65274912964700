import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa';
import { Slider } from './components/Slider';
export const MobileMenu = () => {
  const [toggle, setToggle] = useState(false);

  const toggleMobileSlider = () => {
    setToggle(!toggle);
    document.body.classList.toggle('no-scroll', !toggle);
  };

  return (
    <>
      {!toggle ? (
        <FaBars
          onClick={toggleMobileSlider}
          fill="black"
          size="2rem"
          style={{ marginLeft: '15px', zIndex: 1000, position: 'absolute' }}
        />
      ) : (
        <FaPlus
          onClick={toggleMobileSlider}
          fill="black"
          size="2rem"
          style={{
            marginLeft: '15px',
            zIndex: 1000,
            position: 'absolute',
            rotate: '45deg',
          }}
        />
      )}
      <Slider toggle={toggle} toggleMobileSlider={toggleMobileSlider} />
    </>
  );
};
