export const formatearFecha = (date) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  };
  const formatter = new Intl.DateTimeFormat('es-AR', options);
  return `${formatter.format(date)}`;
};

export const formatDateToDDMMYYYY = (dateString) => {
  const dateObject = new Date(dateString);
  const year = dateObject.getUTCFullYear();
  const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getUTCDate()).padStart(2, '0');
  return `${day}-${month}-${year}`;
};

export const formatDateToYYYYMMDD = (dateString) => {
  const dateObject = new Date(dateString);
  const year = dateObject.getUTCFullYear();
  const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

