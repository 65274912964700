import React, { useEffect, useState } from 'react';
import './MainDashboard.scss';
import DashboardOptions from './DashboardOptions';
import AdminDashboardOptions from './AdminDashboardOptions';
import { rolenames, roles } from '../../../constants';

const MainDashboard = (props) => {

  return (
    <div className="container p-4">
      <h5 className="card-title mb-3">
        ¡Bienvenido/a, {props.user.socialReason}!{' '}
        {rolenames[props.user.role]}
      </h5>
      {props.user?.role !== roles.supreme ? (
        <DashboardOptions user={props.user} />
      ) : (
        <AdminDashboardOptions role={props.user.role} />
      )}
    </div>
  );
};

export default MainDashboard;
