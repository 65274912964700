import React, { useEffect, useState } from 'react'
import { StatsUsers, StatsPolizas, StatsPolizasTypes, StatsPolizasCompanies } from './components'
import { useAppDispatch } from '../../redux/hooks'
import { getPolizas } from '../../redux/polizas';


export const StatsDisplay = ({ tab }) => {
  const dispatch = useAppDispatch();

  const [polizas, setPolizas] = useState([]);

  const handleGetPolizas = async () => {
    const res = await dispatch(getPolizas('?limit=5000000&page=1&expired=false&anulled=false')).unwrap();
    if (!res) return;
    setPolizas(res.docs);
  }

  useEffect(() => {
    handleGetPolizas();
  }, []);

  if (tab === 'usuarios') return <StatsUsers />
  if (tab === 'polizas') return <StatsPolizas polizas={polizas} />
  if (tab === 'polizas-por-tipo') return <StatsPolizasTypes polizas={polizas} />
  if (tab === 'polizas-por-compania') return <StatsPolizasCompanies polizas={polizas} />
}