import {
  adminSidebar,
  clientSidebar,
  supremeSidebar,
} from '../../../constants/sidebarOptions';
import { NavLink } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaUserCircle } from 'react-icons/fa';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { usersSelector } from '../../../redux/users';
import { logout } from '../../../redux/auth';
import './Slider.scss';

export const Slider = ({ toggle, toggleMobileSlider }) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(usersSelector.userData);
  const userType = {
    client: clientSidebar,
    admin: adminSidebar,
    supreme: supremeSidebar,
  };

  const sidebarOptions = userType[user.role];

  const handleLogout = () => {
    dispatch(logout());
    toggleMobileSlider();
  }

  return (
    <div className={`mobileSliderMenuWrapper ${toggle && 'openMenuSlide'}`}>
      <div>
        {user &&
          toggle &&
          sidebarOptions.map((item, index) => (
            <NavLink
              onClick={toggleMobileSlider}
              to={item.path}
              key={index}
              className={({ isActive }) =>
                isActive ? 'sidebarLink activeSidebarItem' : 'sidebarLink'
              }
            >
              <div className="sidebarIcon sidebarIconMobile">{item.icon}</div>
              <div className="sidebarLinkText">{item.name}</div>
            </NavLink>
          ))}
      </div>
      <div style={{ marginBottom: '100px' }}>
        <NavLink
          onClick={toggleMobileSlider}
          to="/perfil"
          className={({ isActive }) =>
            isActive ? 'sidebarLink activeSidebarItem' : 'sidebarLink'
          }
        >
          <div className="sidebarIcon sidebarIconMobile">
            <FaUserCircle />
          </div>
          <div className="sidebarLinkText">Mi cuenta</div>
        </NavLink>
        <div
          className="logoutOption"
          onClick={handleLogout}
        >
          <span className="sidebarLink">
            <div className="sidebarIcon sidebarIconMobile">
              <FaSignOutAlt />
            </div>
            <div className="sidebarLinkText">Cerrar sesión</div>
          </span>
        </div>
      </div>
    </div>
  );
};
