import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorSideAlert } from "../alerts";
import { essentialsStoreKey } from "./essentials.const";


const essentialsPath = `${process.env.REACT_APP_BACKEND_URL}/essentials`;

export const getInsuranceCompanies = createAsyncThunk(
  `${essentialsStoreKey}/getInsuranceCompanies`,
  async (_, thunkApi) => {
    try {
      const response = await axios.get(`${essentialsPath}/insurance-companies`);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.message));
      return [];
    }
  }
);

export const getInsuranceTypes = createAsyncThunk(
  `${essentialsStoreKey}/getInsuranceTypes`,
  async (_, thunkApi) => {
    try {
      const response = await axios.get(`${essentialsPath}/insurance-types`);
      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.message));
      return [];
    }
  }
);