import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import '../Auth.scss';
import { changePassword } from '../../../redux/auth';
import { useAppDispatch } from '../../../redux/hooks';
import { warningSideAlert } from '../../../redux/alerts';

const PaswordReset = () => {
  const dispatch = useAppDispatch();

  const [JWT, setJWT] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [passwords, setPasswords] = useState({
    newPassword: '',
    confirmNewPassword: '',
  });
  const navigate = useNavigate();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    if (passwords.newPassword !== passwords.confirmNewPassword) dispatch(warningSideAlert('Las contraseñas no coinciden'));

    if (passwords.newPassword === '') dispatch(warningSideAlert('Por favor coloca tu nueva contraseña'));

    if (!passwordRegex.test(passwords.newPassword)) dispatch(warningSideAlert('La contraseña debe contener al menos una mayúscula, un número y un caracter especial (!@#$%^&*) y 8 de longitud'));

    const changed = await dispatch(changePassword({ passwords, JWT })).unwrap();

    if (changed) navigate('/login');
  };
  const handleOnChange = (e) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === 'confirmNewPassword' && e.target.value) {
      if (!passwordRegex.test(e.target.value)) return setIsValidPassword(false);
      else return setIsValidPassword(true);
    } else if (e.target.name === 'confirmNewPassword' && !e.target.value) return setIsValidPassword(true);
  };

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  useEffect(() => {
    setJWT(token);
  }, [token]);
  return (
    <>
      <div className="formContainer">
        <img src="/images/logo.png" alt="Logo" />
        <form onSubmit={handleOnSubmit}>
          <h2>CREÁ TU NUEVA CONTRASEÑA</h2>
          <label>Nueva Contraseña</label>
          <input
            name="newPassword"
            type="password"
            value={passwords.newPassword}
            onChange={handleOnChange}
          />
          <label>Confirmar Nueva Contraseña</label>
          <input
            name="confirmNewPassword"
            type="password"
            value={passwords.confirmNewPassword}
            onChange={handleOnChange}
          />
          <button
            disabled={!isValidPassword}
            type="submit"
            className="btn btn-danger">
            Cambiar contraseña
          </button>
          {!isValidPassword &&
            <>
              <p className="password-requirements mt-2 mb-1">La contraseña debe contener al menos:</p>
              <div className="password-requirements-wrapper">
                <ul className='password-requirements'>
                  <li>1 número</li>
                  <li>1 mayúscula</li>
                  <li>1 caracter especial (!@#$%^&*)</li>
                  <li>Mínimo 8 caracteres</li>
                </ul>
              </div>
            </>}
        </form>
      </div>
    </>
  );
};

export default PaswordReset;
