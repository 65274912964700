import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { medicalProviders, medics, states } from '../../../constants';
import RadioInputSelector from '../../../components/RadioInputSelector/RadioInputSelector';
import { useAppDispatch } from '../../../redux/hooks'
import { errorSideAlert } from '../../../redux/alerts';
import { createStudy } from '../../../redux/studies';

const NewStudy = () => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate();

  const [newStudy, setNewStudy] = useState({
    clinic: '',
    medic: '',
    patients: [],
    orderedBy: '',
    details: '',
    state: '',
    type: ''
  });
  const [isMedic, setIsMedic] = useState(false);
  const [medicSelectedOption, setMedicSelectedOption] = useState({ label: '', value: '' });
  const [medicalProviderSelectedOption, setMedicalProviderSelectedOption] = useState({ label: '', value: '' });

  const handleSubmitNewStudy = async (e) => {
    e.preventDefault()
    if (newStudy.patients.length < 1) return dispatch(errorSideAlert('Debes cargar al menos 1 paciente.'))
    const res = await dispatch(createStudy(newStudy)).unwrap();
    if (!res) return

    navigate(`/medicina-laboral/${res._id}`);
  };

  const onChangeNewStudy = (e) => {
    setNewStudy({
      ...newStudy,
      [e.target.name]: e.target.value,
    });
  };

  const onCreateMedic = (e) => {
    setMedicSelectedOption({
      label: e,
      value: e
    })
    const auxStudy = { ...newStudy }
    delete auxStudy.medicEmail
    delete auxStudy.clinic
    delete auxStudy.clinicEmail
    setNewStudy({
      ...auxStudy,
      medic: e,
    })
  }

  const onChangeMedic = (e) => {
    if (!e) return
    const auxStudy = { ...newStudy }
    delete auxStudy.clinic
    delete auxStudy.clinicEmail
    if (e?.label && e?.value.includes('@')) {
      setNewStudy({
        ...auxStudy,
        medic: e.label,
        medicEmail: e.value
      })
    } else if (e?.label && !e?.value.includes('@')) {
      delete auxStudy.medicEmail
      setNewStudy({
        ...auxStudy,
        medic: e.label,
      })
    }
    setMedicSelectedOption({
      value: e.value,
      label: e.label
    })
  }

  const onCreateMedicalProvider = (e) => {
    setMedicalProviderSelectedOption({
      label: e,
      value: e
    })
    const auxStudy = { ...newStudy }
    delete auxStudy.clinicEmail
    delete auxStudy.medic
    delete auxStudy.medicEmail
    setNewStudy({
      ...auxStudy,
      clinic: e,
    })
  }

  const onChangeMedicalProvider = (e) => {
    if (!e) return
    const auxStudy = { ...newStudy }
    delete auxStudy.medic
    delete auxStudy.medicEmail
    if (e?.label && e?.value.includes('@')) {
      setNewStudy({
        ...auxStudy,
        clinic: e.label,
        clinicEmail: e.value
      })
    } else if (e?.label && !e?.value.includes('@')) {
      delete auxStudy.clinicEmail
      setNewStudy({
        ...auxStudy,
        clinic: e.label,
      })
    }
    setMedicalProviderSelectedOption({
      value: e.value,
      label: e.label
    })
  }

  const addPatient = () => {
    setNewStudy({
      ...newStudy,
      patients: [...(newStudy.patients || []), { socialReason: '', dni: '', phone: '' }],
    });
  };

  const deletePatient = (indexToRemove) => {
    setNewStudy((prevNewStudy) => {
      const updatedPatients = prevNewStudy.patients.filter(
        (_, index) => index !== indexToRemove,
      );
      return { ...prevNewStudy, patients: updatedPatients };
    });
  };

  const onChangePatient = (e, index) => {
    const patients = [...newStudy.patients];
    patients[index][e.target.name] = e.target.value;
    setNewStudy({
      ...newStudy,
      patients,
    });
  };

  return (
    <>
      <h1>Cargar Solicitud de Estudio</h1>
      <div className="contentContainer">
        <form onSubmit={handleSubmitNewStudy}>
          <div className="mt-2" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div style={{ maxWidth: '380px' }}>
              <label>
                Solicitante <span className="requiredFormField">*</span>
              </label>
              <input
                required
                type="text"
                name="orderedBy"
                className="form-control"
                onChange={onChangeNewStudy}
              />
              <RadioInputSelector
                title="Seleccione una opción"
                setState={setIsMedic}
                state={isMedic}
                confirm="Médico"
                deny="Prestador"
                required
              />
              {isMedic ? (
                <>
                  <label>
                    Nombre y apellido del médico{' '}
                    <span className="requiredFormField">*</span>
                  </label>
                  <CreatableSelect
                    name='medic'
                    value={medicSelectedOption}
                    onInputChange={onChangeMedic}
                    onChange={onChangeMedic}
                    onCreateOption={onCreateMedic}
                    options={medics}
                    placeholder="Escribe para autocompletar..."
                  />
                  {medics.map(medic => {
                    if (medic.value === newStudy.medicEmail)
                      return < p className='infoMessage' key={newStudy.medicEmail}> Se le informará a {newStudy.medic} <br />al email {newStudy.medicEmail}</p>
                  }
                  )
                  }
                </>
              ) : (
                <>
                  <label>
                    Prestador <span className="requiredFormField">*</span>
                  </label>
                  <CreatableSelect
                    name='clinic'
                    value={medicalProviderSelectedOption}
                    onInputChange={onChangeMedicalProvider}
                    onChange={onChangeMedicalProvider}
                    onCreateOption={onCreateMedicalProvider}
                    options={medicalProviders}
                    placeholder="Escribe para autocompletar..."
                  />
                  {medicalProviders.map(provider => {
                    if (provider.value === newStudy.clinicEmail)
                      return < p className='infoMessage' key={newStudy.clinicEmail}> Se le informará a {newStudy.clinic} <br />al email {newStudy.clinicEmail}</p>
                  }
                  )
                  }
                </>
              )}
              <label>
                Estudio médico / servicio a realizar{' '}
                <span className="requiredFormField">*</span>
              </label>
              <input
                type="text"
                className="form-control mb-2"
                name="type"
                onChange={onChangeNewStudy}
                required
              />
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
              gap: '15px',
              flexWrap: 'wrap',
            }}
          >
            {newStudy.patients?.length &&
              newStudy.patients.map((patient, index) => {
                return (
                  <div key={index} className="card" style={{ padding: '0.5rem' }}>
                    <label className="mt-0">
                      Nombre y apellido <span className="requiredFormField">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="socialReason"
                      onChange={(e) => onChangePatient(e, index)}
                      required
                    />
                    <label>
                      DNI<span className="requiredFormField">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="dni"
                      onChange={(e) => onChangePatient(e, index)}
                      required
                    />
                    <label>
                      Teléfono
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      onChange={(e) => onChangePatient(e, index)}
                    />
                    <label>
                      Fecha de turno <span className="requiredFormField">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      onChange={(e) => onChangePatient(e, index)}
                      required
                    />
                    <label>
                      Horario
                    </label>
                    <input type="time" className="form-control" name="time" onChange={(e) => onChangePatient(e, index)} />
                    <button
                      className="btn btn-danger mt-2"
                      type="button"
                      onClick={() => deletePatient(index)}
                    >
                      Eliminar
                    </button>
                  </div>
                );
              })}
          </div>
          <div className="mt-2" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div style={{ maxWidth: '380px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignContent: "center" }}>
                <p className="text-center mb-0">Añadir paciente</p>
                <button className="btn btn-info w-90 mb-4" style={{ margin: "auto" }} onClick={addPatient} type="button">
                  +
                </button>
              </div>
              <label>Observaciones</label>
              <textarea
                className="form-control"
                rows="3"
                name="details"
                onChange={onChangeNewStudy}
              ></textarea>
              <label>
                Provincia <span className="requiredFormField">*</span>
              </label>
              <select
                required
                onChange={onChangeNewStudy}
                name="state"
                className="form-select"
              >
                <option value="" hidden>Selecciona una provincia</option>
                {states.map((state, index) => {
                  return (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  );
                })}
              </select>

              <button
                type="submit"
                className="btn btn-primary mt-3"
              >
                Cargar estudio
              </button>
            </div>
          </div>
        </form>
      </div >
    </>
  );
};

export default NewStudy;
