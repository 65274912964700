import { useAppSelector } from '../../redux/hooks'
import { usersSelector } from '../../redux/users'
import { roles } from '../../constants'
import { expiresInOneWeek, isExpired } from '../../utils/isExpired';
import { Chip } from './Chip';

export const DetailChips = (props) => {
  const user = useAppSelector(usersSelector.userData);
  if (props.poliza)
    return (
      <div className="chipsWrapperCentered">
        {user.role === roles.supreme &&
          expiresInOneWeek(props.poliza.expiresAt) ? (
          <Chip color="purple" label="Vence pronto" />
        ) :
          isExpired(props.poliza.expiresAt) && (
            <Chip color="warning" label="Vencida" />
          )
        }
        {props.poliza.anulled && <Chip color="danger" label="Anulada" />}
        {props.poliza.renewed && <Chip color="primary" label="Renovada" />}
      </div>
    )
  else if (props.study)
    return (
      <div className="chipsWrapperCentered">
        {props.study.invoiced && (
          <Chip
            color="success"
            label="Facturado"
            sx={{ marginBottom: '0.6rem' }}
          />
        )}
        {props.study.fulfilled && (
          <Chip
            color="primary"
            label="Realizado"
            sx={{ marginBottom: '0.6rem' }}
          />
        )}
        {props.study.anulled && (
          <Chip
            sx={{ marginTop: '0px', marginBottom: '10px' }}
            color="danger"
            label="Anulado"
          />
        )}
      </div>
    )
  else if (props.siniestro)
    return (<div className="chipsWrapperCentered">
      {!!props.siniestro.denunciaFiles.length && (
        <Chip sx={{ marginBottom: '0.6rem' }} color="success" label="Denuncia cargada" />
      )}
    </div>
    )
  else if (props.user)
    return (
      <div className="chipsWrapperCentered">
        {!props.user.status && (
          <Chip
            sx={{ marginTop: '20px', marginBottom: '-5px' }}
            color="danger"
            label="Usuario deshabilitado"
          />
        )}
      </div>
    )
  else return;
}
