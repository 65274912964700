const PolizaSelector = (props) => {
  const { polizas } = props;
  return (
    <>
      <label>
        Seleccione la póliza de cobertura <span className="requiredFormField">*</span>
      </label>
      <select
        onChange={props.handleOnChangeDenunciante}
        name="polizaId"
        className="form-select"
        required
      >
        <option value="" hidden>
          Seleccione la póliza
        </option>
        {polizas.map((poliza, index) => {
          if (!poliza.anulled)
            return (
              <option key={index} value={JSON.stringify(poliza)}>
                Póliza: {poliza.polizaNumber} - Aseguradora:{' '}
                {poliza.insuranceCompany}
              </option>
            );
        })}
      </select>
    </>
  );
};

export default PolizaSelector;
