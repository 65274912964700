import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import { useAppSelector } from '../../redux/hooks';
import { usersSelector } from '../../redux/users';
import './ContentPositioner.scss';

const ContentPositioner = ({ children, loggedIn, loadingSession }) => {
  const user = useAppSelector(usersSelector.userData);
  const [history, setHistory] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setHistory(location.pathname + location.search);
  }, []);

  useEffect(() => {
    if (loggedIn && !loadingSession) navigate(history);
  }, [loggedIn, loadingSession]);

  if (user && location.pathname === '/login') navigate('/');

  if (!user) {
    return <div className="contentWithoutSidebar">{children}</div>;
  } else {
    return (
      <div className="content">
        <div className="redBackground">
          <Navbar />
        </div>
        <div className="contentPositioner">
          <div className="displayContent">{children}</div>
        </div>
      </div>
    );
  }
};

export default ContentPositioner;
