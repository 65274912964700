import {
  createSlice
} from '@reduxjs/toolkit';
import {
  authStoreKey,
  autoLogin,
  loginUser,
  logout
} from '.';

const initialState = {
  isLoading: false,
  isLoadingSession: false,
};

export const authSlice = createSlice({
  name: authStoreKey,
  initialState,
  reducers: {},
  extraReducers(builder) {
    // loginUser
    builder.addCase(loginUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(loginUser.fulfilled, (state) => {
      state.isLoading = false;
    });
    //autoLogin
    builder.addCase(autoLogin.pending, (state) => {
      state.isLoadingSession = true;
    });
    builder.addCase(autoLogin.rejected, (state) => {
      state.isLoadingSession = false;
    });
    builder.addCase(autoLogin.fulfilled, (state) => {
      state.isLoadingSession = false;
    });
    // logout
    builder.addCase(logout.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(logout.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isLoading = false;
    });
  }
});