import React, { useEffect, useState } from 'react';
import ThirdDriving from './EditSiniestroComponents/ThirdDriving';
import ThirdInvolved from './EditSiniestroComponents/ThirdInvolved';
import TypeSelector from './EditSiniestroComponents/TypeSelector';
import RadioInputSelector from './EditSiniestroComponents/RadioInputSelector';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { formatDateToYYYYMMDD } from '../../../utils/date';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getSiniestroDetail, updateSiniestro, siniestrosSelectors } from '../../../redux/siniestros';
import Spinner from '../../../components/Spinner/Spinner';
import styles from '../NewSiniestro/ReportSiniestro.module.css';

const EditSiniestro = () => {
  const dispatch = useAppDispatch();

  const loadingSiniestro = useAppSelector(siniestrosSelectors.isLoading);

  const navigate = useNavigate();
  const { sid } = useParams();
  const [loading, setLoading] = useState(false);
  const [thirdDriving, setThirdDriving] = useState(true);
  const [thirdInvolved, setThirdInvolved] = useState(false);
  const [denunciante, setDenunciante] = useState({
    userId: '',
    polizaId: '',
    siniestroNumber: '',
  });
  const [siniestro, setSiniestro] = useState({
    guilt: '',
    tipoSiniestro: '',
    date: '',
    direccion: '',
    entreCalles: '',
    localidad: '',
    detalle: '',
  });
  const [conductor, setConductor] = useState({
    socialReason: '',
    document: {
      documentType: '',
      documentNumber: '',
    },
  });
  const [tercero, setTercero] = useState({
    socialReason: '',
    document: {
      documentType: '',
      documentNumber: '',
    },
    insuranceCompany: '',
    polizaNumber: '',
    domain: '',
  });
  const [datosDenunciante, setDatosDenunciante] = useState({
    socialReason: '',
    email: '',
  });

  const handleGetSiniestro = async () => {
    const res = await dispatch(getSiniestroDetail(sid)).unwrap();
    if (!res) return;

    setThirdDriving(true);
    setThirdInvolved(true);
    const { tercero, conductor } = res;
    setDenunciante({
      userId: res.userId,
      polizaId: res.polizaId,
      siniestroNumber: res.siniestroNumber,
      denunciaFiles: res.denunciaFiles,
    });
    setDatosDenunciante({
      socialReason: res.userId.socialReason,
      email: res.userId.email,
      document: res.userId.document,
    });
    setSiniestro(res.siniestro);

    if (conductor.socialReason) {
      setConductor(conductor);
      setThirdDriving(false);
    }

    if (
      tercero.socialReason ||
      tercero.document.documentType ||
      tercero.document.documentNumber ||
      tercero.insuranceCompany ||
      tercero.polizaNumber ||
      tercero.domain ||
      tercero.thirdFiles
    ) {
      setTercero(res.tercero);
      setThirdInvolved(false);
    }
  };

  useEffect(() => {
    handleGetSiniestro();
  }, [sid]);

  const handleOnChangeDenunciante = (e) => {
    setDenunciante({ ...denunciante, [e.target.name]: e.target.value });
  };
  const handleOnChangeSiniestro = (e) => {
    setSiniestro({ ...siniestro, [e.target.name]: e.target.value });
  };
  const handleOnChangeConductor = (e) => {
    if (e.target.name === 'documentType' || e.target.name === 'documentNumber') {
      return setConductor({
        ...conductor,
        document: {
          ...conductor.document,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      return setConductor({ ...conductor, [e.target.name]: e.target.value });
    }
  };
  const handleOnChangeTercero = (e) => {
    if (e.target.name === 'documentType' || e.target.name === 'documentNumber') {
      return setTercero({
        ...tercero,
        document: {
          ...tercero.document,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      return setTercero({ ...tercero, [e.target.name]: e.target.value });
    }
  };

  const handleRemoveConductor = (index) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este archivo?')) {
      const updatedFiles = conductor.driverFiles.filter((_, i) => i !== index);
      setConductor({ ...conductor, driverFiles: updatedFiles });
    }
  };

  const handleRemoveTercero = (index) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este archivo?')) {
      const updatedFiles = tercero.thirdFiles.filter((_, i) => i !== index);
      setTercero({ ...tercero, thirdFiles: updatedFiles });
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    const data = { denunciante, siniestro, conductor, tercero };
    formData.append('type', 'siniestros');
    formData.append('data', JSON.stringify({ data }));

    const generalFilesInput = document.querySelector('input[name="siniestroFiles"]');
    if (generalFilesInput) {
      const generalFiles = generalFilesInput.files;
      for (let i = 0; i < generalFiles.length; i++) {
        formData.append('siniestroFiles', generalFiles[i]);
      }
    }

    const driverFilesInput = document.querySelector('input[name="driverFiles"]');
    if (driverFilesInput) {
      const driverFiles = driverFilesInput.files;
      for (let i = 0; i < driverFiles.length; i++) {
        formData.append('driverFiles', driverFiles[i]);
      }
    }

    const thirdFilesInput = document.querySelector('input[name="thirdFiles"]');
    if (thirdFilesInput) {
      const thirdFiles = thirdFilesInput.files;
      for (let i = 0; i < thirdFiles.length; i++) {
        formData.append('thirdFiles', thirdFiles[i]);
      }
    }

    const denunciaFilesInput = document.querySelector('input[name="denunciaFiles"]');
    if (denunciaFilesInput) {
      const denunciaFiles = denunciaFilesInput.files;
      for (let i = 0; i < denunciaFiles.length; i++) {
        formData.append('denunciaFiles', denunciaFiles[i]);
      }
    }

    const res = await dispatch(updateSiniestro({ sid, formData })).unwrap();
    setLoading(false);
    if (!res) return;

    navigate(`/siniestros/${res._id}`);
  };

  if (loadingSiniestro)
    return (
      <>
        <h1> Editar Siniestro</h1>
        <div className="contentContainer">
          <Spinner />
        </div>
      </>
    );

  return (
    <>
      <h1> Editar Siniestro</h1>
      <div className="contentContainer">
        {siniestro &&
          <>
            <form onSubmit={handleOnSubmit} className={styles.reportForm}>
              <div className={styles.doubleInputWrapper}>
                <div className={styles.labelAndInputWrapper}>
                  <label>
                    Asegurado <span className="requiredFormField">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="socialReason"
                    value={`${datosDenunciante.socialReason}`}
                    disabled={true}
                    type="text"
                    required
                  />
                </div>
                <div className={styles.labelAndInputWrapper}>
                  <label>
                    Email Asegurado <span className="requiredFormField">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="email"
                    value={`${datosDenunciante.email}`}
                    disabled={true}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className={styles.doubleInputWrapper}>
                <div className={styles.labelAndInputWrapper}>
                  <label>
                    Tipo de documento <span className="requiredFormField">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="document.documentType"
                    value={`${datosDenunciante?.document?.documentType}`}
                    disabled={true}
                    type="text"
                    required
                  />
                </div>
                <div className={styles.labelAndInputWrapper}>
                  <label>
                    Nº de documento <span className="requiredFormField">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="document.documentNumber"
                    value={`${datosDenunciante?.document?.documentNumber}`}
                    disabled={true}
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className={styles.doubleInputWrapper}>
                <div className={styles.labelAndInputWrapper}>
                  <label>Póliza</label>
                  <select className="form-select" name="polizaId" disabled>
                    <option value={denunciante.polizaId._id}>
                      Póliza: {denunciante.polizaId.polizaNumber} - Aseguradora:{' '}
                      {denunciante.polizaId.insuranceCompany}
                    </option>
                  </select>
                </div>
                <div className={styles.labelAndInputWrapper}>
                  <label>
                    Seleccione el culpable del siniestro <span className="requiredFormField">*</span>
                  </label>
                  <select
                    className="form-select"
                    name="guilt"
                    onChange={handleOnChangeSiniestro}
                    required
                    value={siniestro.guilt}
                  >
                    <option value="" disabled hidden>
                      Seleccione el culpable del siniestro
                    </option>
                    <option value="Mi culpa">Mi culpa</option>
                    <option value="No fue mi culpa">No fué mi culpa</option>
                    <option value="Auto estacionado">El auto estaba estacionado (Sin conductor)</option>
                  </select>
                </div>
              </div>
              <div className={styles.doubleInputWrapper}>
                <div className={styles.labelAndInputWrapper}>
                  <TypeSelector
                    handleOnChangeSiniestro={handleOnChangeSiniestro}
                    tipoSiniestro={siniestro.tipoSiniestro}
                  />
                </div>
                <div className={styles.labelAndInputWrapper}>
                  <label>Selecciona una fecha:</label>
                  <input
                    type="date"
                    className="form-control"
                    id="fecha"
                    name="date"
                    onChange={handleOnChangeSiniestro}
                    value={siniestro.date ? formatDateToYYYYMMDD(siniestro.date) : ''}
                    required
                  />
                </div>
              </div>
              <div className={styles.doubleInputWrapper}>
                <div className={styles.labelAndInputWrapper}>
                  <label>
                    Calle y altura donde ocurrió el suceso <span className="requiredFormField">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="direccion"
                    onChange={handleOnChangeSiniestro}
                    value={siniestro.direccion}
                    required
                  />
                </div>
                <div className={styles.labelAndInputWrapper}>
                  <label>
                    ¿Entre qué calles sucedió? <span className="requiredFormField">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="entreCalles"
                    onChange={handleOnChangeSiniestro}
                    required
                    value={siniestro.entreCalles}
                  />
                </div>
              </div>
              <label>
                ¿En qué localidad? <span className="requiredFormField">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="localidad"
                onChange={handleOnChangeSiniestro}
                value={siniestro.localidad}
                required
              />
              <label>
                Detállanos lo ocurrido <span className="requiredFormField">*</span>
              </label>
              <textarea
                className="form-control"
                rows="3"
                name="detalle"
                onChange={handleOnChangeSiniestro}
                value={siniestro.detalle}
                required
              ></textarea>
              <div className="form-text">Contános cómo sucedió el siniestro. </div>
              <label className="mt-3">Subí acá fotos/documentos</label>
              <input
                className="form-control"
                type="file"
                name="siniestroFiles"
                multiple
                required={siniestro.siniestroFiles?.length === 0}
              />
              <div className="form-text">
                Cargue los archivos del tercero: (Cedula del vehículo, Registro del conductor, DNI de
                conductor, fotos delvehículo de los daños y que se vea la patente, denuncia policial.
                Todos los documentos frente y dorso)
              </div>
              <RadioInputSelector
                title={`¿Se encontraba ${datosDenunciante.socialReason} conduciendo el vehículo?`}
                setState={setThirdDriving}
                defaultValue={thirdDriving}
              />
              {thirdDriving ? (
                false
              ) : (
                <ThirdDriving
                  name={thirdDriving}
                  conductor={conductor}
                  handleOnChangeConductor={handleOnChangeConductor}
                  handleRemoveConductor={handleRemoveConductor}
                />
              )}
              <RadioInputSelector
                title={`¿Hay terceros involucrados?`}
                setState={setThirdInvolved}
                defaultValue={thirdInvolved}
                required
              />
              {thirdInvolved ? (
                <ThirdInvolved
                  name={thirdInvolved}
                  tercero={tercero}
                  handleOnChangeTercero={handleOnChangeTercero}
                  handleRemoveTercero={handleRemoveTercero}
                />
              ) : (
                false
              )}
              <div className="siniestroDenunciaEdit">
                <h5 className="text-center">Denuncia del siniestro en la compañía de seguros</h5>
                <label className="mt-3">Número de siniestro</label>
                <input
                  type="text"
                  className="form-control"
                  name="siniestroNumber"
                  onChange={handleOnChangeDenunciante}
                  value={denunciante.siniestroNumber}
                />
                <label className="mt-3">Archivos de la denuncia</label>
                <input className="form-control" type="file" name="denunciaFiles" multiple />
              </div>
              <div className={styles.submitButtonWrapper}>
                <button type="submit" className="btn btn-warning mt-3" disabled={loading}>
                  {loading ? (
                    <div className="spinner-border text-light" role="status"></div>
                  ) : (
                    'Editar siniestro'
                  )}
                </button>
              </div>
            </form>
          </>}
      </div>
    </>
  );
};

export default EditSiniestro;
