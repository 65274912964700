import React from 'react';
import { Link } from 'react-router-dom';
import { formatearFecha } from '../../../utils/date';
import { CardChips } from '../../../components/Chips/CardChips';

const StudyCard = (props) => {
  const { updateStatus } = props;

  return (
    <div className="card text-center mb-3 mx-4 w-90">
      <div className="card-body">
        <CardChips study={props.study} />
        <h5 className="card-title text-start">
          Solicitante: {props.study.orderedBy}
        </h5>
        <p className="card-text text-start mb-1">
          <b>Solicitado para:</b> {props.study.patients.length} persona
          {props.study.patients.length > 1 ? 's' : ''}
        </p>
        {props.study.medic ? (
          <p className="card-text text-start mb-1">
            <b>Médico: </b> {props.study.medic}
          </p>
        ) : (
          <p className="card-text text-start mb-1">
            <b>Prestador: </b> {props.study.clinic}
          </p>
        )}
        <p className="card-text text-start">
          <b>Tipo / Servicio: </b> {props.study.type}
        </p>
        {!props.study.anulled && <div style={{ display: 'flex', justifyContent: 'space-between', gap: '0.5rem' }}>
          {!props.study.invoiced &&
            <button className='btn btn-success mb-1' style={{ width: "100%" }} onClick={() => updateStatus('invoiced', props.study._id)}>Marcar como facturado</button>
          }
          {!props.study.fulfilled &&
            <button className='btn btn-dark mb-1' style={{ width: "100%" }} onClick={() => updateStatus('fulfilled', props.study._id)}>Marcar como realizado</button>
          }
        </div>}
        <Link
          to={`/medicina-laboral/${props.study._id}`}
          className="btn btn-primary"
        >
          Ver solicitud de estudios
        </Link>
      </div>
      <div className="card-footer text-body-secondary rowCardList row row-cols-sm-1 row-cols-md-1 row-cols-lg-2 ">
        <p className="mb-md-3 mb-lg-0 text-lg-start text-md-center">
          <b>Cargado el</b> {formatearFecha(new Date(props.study.createdAt))}
        </p>
      </div>
    </div>
  );
};

export default StudyCard;
