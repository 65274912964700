import { configureStore } from '@reduxjs/toolkit';
import { userSlice, usersStoreKey } from './users';
import { authSlice, authStoreKey } from './auth';
import { alertStoreKey, alertsSlice } from './alerts';
import { polizasSlice, polizasStoreKey } from './polizas';
import { studiesStoreKey, studiesSlice } from './studies';
import { siniestrosStoreKey, siniestrosSlice } from './siniestros';
import { statsSlice, statsStoreKey } from './stats';
import { essentialsSlice, essentialsStoreKey } from './essentials';

export const store = configureStore({
  reducer: {
    [authStoreKey]: authSlice.reducer,
    [usersStoreKey]: userSlice.reducer,
    [polizasStoreKey]: polizasSlice.reducer,
    [siniestrosStoreKey]: siniestrosSlice.reducer,
    [studiesStoreKey]: studiesSlice.reducer,
    [alertStoreKey]: alertsSlice.reducer,
    [statsStoreKey]: statsSlice.reducer,
    [essentialsStoreKey]: essentialsSlice.reducer,
  },
});

export const RootState = () => store.getState();
export const AppDispatch = store.dispatch;