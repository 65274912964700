import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/Spinner/Spinner';
import { formatearFecha } from '../../../utils/date';
import { roles } from '../../../constants';
import { usersSelector } from '../../../redux/users';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getSiniestroDetail, siniestrosSelectors } from '../../../redux/siniestros';
import { DetailChips } from '../../../components/Chips/DetailChips';

const SiniestroDetail = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(usersSelector.userData);
  const loadingSiniestro = useAppSelector(siniestrosSelectors.isLoading);

  const { sid } = useParams();
  const [siniestro, setSiniestro] = useState(null);
  const [conductor, setConductor] = useState(false);
  const [tercero, setTercero] = useState(false);
  const [downloadingFiles, setDownloadingFiles] = useState({
    general: false,
    conductor: false,
    tercero: false,
    denunciaFiles: false,
  });

  const handleGetSiniestroDetail = async () => {
    const res = await dispatch(getSiniestroDetail(sid)).unwrap();

    if (!res) return;

    const { tercero, conductor } = res;
    setSiniestro(res);
    if (conductor.socialReason) setConductor(true);
    if (
      tercero.socialReason ||
      tercero.document.documentType ||
      tercero.document.documentNumber ||
      tercero.insuranceCompany ||
      tercero.polizaNumber ||
      tercero.domain ||
      tercero.thirdFiles
    )
      setTercero(true);
  };

  useEffect(() => {
    handleGetSiniestroDetail();
  }, [sid]);

  const downloadFiles = (imageUrls, nombre) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/siniestros/download-images`,
        { imageUrls },
        {
          withCredentials: true,
          responseType: 'arraybuffer',
        },
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const enlace = document.createElement('a');
        enlace.href = url;
        enlace.setAttribute('download', `siniestro_${nombre}.zip`);
        document.body.appendChild(enlace);
        enlace.click();
        document.body.removeChild(enlace);
        setDownloadingFiles({ ...downloadingFiles, [nombre]: false });
      })
      .catch((error) => {
        // showToast(error.response.data.message ?? errors.genericError, 'error');
        setDownloadingFiles({ ...downloadingFiles, [nombre]: false });
      });
  };

  const handleDownloadGeneralFiles = async () => {
    setDownloadingFiles({ ...downloadingFiles, general: true });
    downloadFiles(siniestro.siniestro.siniestroFiles, 'general');
  };

  const handleDownloadDriverFiles = async () => {
    setDownloadingFiles({ ...downloadingFiles, conductor: true });
    downloadFiles(siniestro.conductor.driverFiles, 'conductor');
  };

  const handleDownloadThirdFiles = async () => {
    setDownloadingFiles({ ...downloadingFiles, tercero: true });
    downloadFiles(siniestro.tercero.thirdFiles, 'tercero');
  };

  const handleDownloadDenunciaFiles = async () => {
    setDownloadingFiles({ ...downloadingFiles, denunciaFiles: true });
    downloadFiles(siniestro.denunciaFiles, 'denunciaFiles');
  };

  if (loadingSiniestro)
    return (
      <>
        <h1>Detalle del siniestro</h1>
        <div className="card text-center mb-3 mx-4" style={{ width: '100%' }}>
          <div className="card-body">
            <Spinner />
          </div>
        </div>
      </>
    );

  return (
    <>
      <h1>Detalle del siniestro</h1>
      <div className="card text-center mb-3 mx-4" style={{ width: '100%' }}>
        <div className="card-body">
          {siniestro && (
            <>
              <DetailChips siniestro={siniestro} />
              <h5 className="card-title">Siniestro de tipo: {siniestro.siniestro.tipoSiniestro}</h5>
              <div className="card text-center mb-3 mx-4">
                <div className="card-body">
                  <p className="mb-1">
                    Suceso ocurrido el: <b>{formatearFecha(new Date(siniestro.siniestro.date))}</b>
                  </p>
                </div>
              </div>
              <h5 className="card-title mt-4">Póliza del asegurado:</h5>
              <div className="card text-center mb-3 mx-4">
                <div className="card-body">
                  <p className="mb-1">
                    <b>Titular:</b>{' '}
                    <Link to={`/usuarios/${siniestro.userId._id}`}>
                      {siniestro.userId.socialReason}
                    </Link>
                  </p>
                  <p className="mb-1">
                    <b>Compañía de seguro:</b> {siniestro.polizaId.insuranceCompany}
                  </p>
                  <p className="mb-1">
                    <b>Número de póliza:</b> {siniestro.polizaId.polizaNumber}
                  </p>
                  {siniestro.polizaId.domain !== '' && (
                    <p className="mb-1">
                      <b>Dominio del vehículo:</b> {siniestro.polizaId.domain}
                    </p>
                  )}
                  <p className="mb-1">
                    <b>Tipo de póliza:</b> {siniestro.polizaId.polizaType}
                  </p>
                  {siniestro.polizaId.polizaType === 'Flota' && siniestro.floatVehicleDomain && (
                    <p className="mb-1">
                      <b>Patente del vehículo involucrado de la póliza:</b>{' '}
                      {siniestro.floatVehicleDomain}
                    </p>
                  )}
                  <Link to={`/polizas/${siniestro.polizaId._id}`} className="btn btn-success">
                    Ver póliza
                  </Link>
                </div>
              </div>
              <h5 className="card-title mt-4">¿Dónde sucedió?</h5>
              <div className="card text-center mb-3 mx-4">
                <div className="card-body">
                  <p className="mb-1">
                    <b>Provincia:</b> {siniestro.siniestro.state}
                  </p>
                  <p className="mb-1">
                    <b>Localidad:</b> {siniestro.siniestro.localidad}
                  </p>
                  <p className="mb-1">
                    <b>Dirección:</b> {siniestro.siniestro.direccion}
                  </p>
                  <p className="mb-1">
                    <b>Entre Calles:</b> {siniestro.siniestro.entreCalles}
                  </p>
                </div>
              </div>
              <h5 className="card-title mt-4">Culpabilidad</h5>
              <div className="card text-center mb-3 mx-4">
                <div className="card-body">
                  <p className="mb-1">
                    {siniestro.siniestro.guilt === 'Mi culpa' && (
                      <>
                        {siniestro.siniestro.guilt} <b>(Culpa del asegurado)</b>
                      </>
                    )}
                  </p>
                </div>
              </div>
              <h5 className="card-title mt-4">Descripción de los hechos:</h5>
              <div className="card text-center mb-3 mx-4">
                <div className="card-body">
                  <p className="mb-1">{siniestro.siniestro.detalle}</p>
                </div>
              </div>
              <h5 className="card-title mt-4">Tipo de siniestro</h5>
              <div className="card text-center mb-3 mx-4">
                <div className="card-body">
                  <p className="mb-1">{siniestro.siniestro.tipoSiniestro}</p>
                </div>
              </div>
              <button
                className="btn btn-primary w-90"
                style={{ margin: 'auto' }}
                onClick={handleDownloadGeneralFiles}
              >
                {downloadingFiles.general ? (
                  <div className="spinner-border text-light" role="status"></div>
                ) : (
                  'Descargar archivos generales'
                )}
              </button>
              <h5 className="card-title mt-4">Conductor</h5>
              <div className="card text-center mb-3 mx-4">
                <div className="card-body">
                  {conductor ? (
                    <>
                      <p>
                        <b>Tipo de Documento:</b> {siniestro.conductor.document.documentType}
                      </p>
                      <p>
                        <b>Número de Documento:</b> {siniestro.conductor.document.documentNumber}
                      </p>
                      <p>
                        <b>Razón Social:</b> {siniestro.conductor.socialReason}
                      </p>
                      <button
                        className="btn btn-primary w-90"
                        style={{ margin: 'auto' }}
                        onClick={handleDownloadDriverFiles}
                      >
                        {downloadingFiles.conductor ? (
                          <div className="spinner-border text-light" role="status"></div>
                        ) : (
                          'Descargar archivos del conductor'
                        )}
                      </button>
                    </>
                  ) : (
                    <p className="mb-0">
                      <b>(Se asume por la falta de datos que conducía el asegurado)</b>
                    </p>
                  )}
                </div>
              </div>

              <h5 className="card-title mt-4">Tercero Involucrado</h5>
              <div className="card text-center mb-3 mx-4">
                <div className="card-body">
                  {tercero ? (
                    <>
                      <p>
                        <b>Tipo de Documento: </b>
                        {siniestro.tercero.document.documentType}
                      </p>
                      <p>
                        <b>Número de Documento: </b>
                        {siniestro.tercero.document.documentNumber}
                      </p>
                      <p>
                        <b>Razón Social:</b> {siniestro.tercero.socialReason}
                      </p>
                      <p>
                        <b>Compañía de Seguro:</b> {siniestro.tercero.insuranceCompany}
                      </p>
                      <p>
                        <b>Número de Póliza:</b> {siniestro.tercero.polizaNumber}
                      </p>
                      <p>
                        <b>Dominio:</b> {siniestro.tercero.domain}
                      </p>
                      <button
                        className="btn btn-primary w-90"
                        style={{ margin: 'auto' }}
                        onClick={handleDownloadThirdFiles}
                      >
                        {downloadingFiles.tercero ? (
                          <div className="spinner-border text-light" role="status"></div>
                        ) : (
                          'Descargar archivos del tercero'
                        )}
                      </button>
                    </>
                  ) : (
                    <p className="mb-0">
                      <b>
                        (Se asume por la falta de datos que no hubo o se tienen datos del/los
                        tercero/s involucrado/s)
                      </b>
                    </p>
                  )}
                </div>
              </div>
              {!!siniestro.denunciaFiles.length ? (
                <>
                  <h5 className="card-title mt-4">Denuncia en la compañía de seguros</h5>
                  <div className="card text-center mb-3 mx-4 p-2">
                    <p className="mb-1">
                      <b>Número del siniestro:</b> {siniestro.siniestroNumber}
                    </p>
                    <button className="btn btn-primary" onClick={handleDownloadDenunciaFiles}>
                      {downloadingFiles.denunciaFiles ? (
                        <div className="spinner-border text-light" role="status"></div>
                      ) : (
                        'Descargar archivos de la denuncia en la compañía de seguros'
                      )}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h5>Cuando el siniestro sea cargado, serás notificado vía email</h5>
                  <h6>(Hasta 72hrs hábiles desde la carga del siniestro)</h6>
                </>
              )}
              {user.role === roles.supreme && (
                <Link to={`/siniestros/editar/${sid}`} className="btn btn-success">
                  EDITAR
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SiniestroDetail;
