import {
  createSlice
} from "@reduxjs/toolkit";
import {
  getUsersStats,
  getPolizasStats,
  statsStoreKey,
} from ".";

const initialState = {
  isLoadingUsersStats: false,
  usersStats: null,
  isLoadingPolizasStats: false,
  polizasStats: null,
};

export const statsSlice = createSlice({
  name: statsStoreKey,
  initialState,
  reducers: {},

  extraReducers(builder) {
    // getUsersStats
    builder.addCase(getUsersStats.pending, (state) => {
      state.isLoadingUsersStats = true;
      state.usersStats = null;
    });
    builder.addCase(getUsersStats.rejected, (state) => {
      state.isLoadingUsersStats = false;
      state.usersStats = null;
    });
    builder.addCase(getUsersStats.fulfilled, (state, action) => {
      state.isLoadingUsersStats = false;
      state.usersStats = action.payload;
    });
    // getPolizasStats
    builder.addCase(getPolizasStats.pending, (state) => {
      state.isLoadingPolizasStats = true;
      state.polizasStats = null;
    });
    builder.addCase(getPolizasStats.rejected, (state) => {
      state.isLoadingPolizasStats = false;
      state.polizasStats = null;
    });
    builder.addCase(getPolizasStats.fulfilled, (state, action) => {
      state.isLoadingPolizasStats = false;
      state.polizasStats = action.payload;
    });
  }
});

// export const { setsiniestrosData } = siniestrosSlice.actions;