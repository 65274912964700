import React from 'react';
import { formatearFecha } from '../../../../../utils/date';
import { Link } from 'react-router-dom';

const UserSiniestros = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        marginTop: '10px',
        gap: '15px',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {props.userSiniestros.map((siniestro, index) => {
        const fechaOcurrido = siniestro.siniestro.date
          ? new Date(siniestro.siniestro.date)
          : false;
        const fechaOcurridoFormateado = fechaOcurrido
          ? formatearFecha(fechaOcurrido)
          : '';

        return (
          <div
            key={`siniestroUser${index}`}
            className="card text-center"
            style={{ width: '280px' }}
          >
            <div className="card-body">
              <p style={{ fontSize: '18px' }} className="card-title mb-0">
                <b>{siniestro.siniestro.tipoSiniestro}</b>
              </p>
              <Link
                to={`/siniestros/${siniestro._id}`}
                className="btn btn-warning mt-2"
              >
                Ver siniesro
              </Link>
            </div>
            <div className="card-footer text-body-secondary ">
              <p className="mb-0 text-center">
                <b>Ocurrió el:</b>
              </p>
              <p className="mb-0 text-center">{fechaOcurridoFormateado}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserSiniestros;
