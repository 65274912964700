import { createSlice } from "@reduxjs/toolkit";
import { updateUser } from ".";

const initialState = {
  isLoading: false,
  userData: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserData(state, action) {
      state.userData = action.payload;
    },
  },

  extraReducers(builder) {
    // updateUser
    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userData = action.payload;
    });
  }
});

export const { updateUserData } = userSlice.actions;
