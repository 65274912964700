import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useAppSelector } from '../redux/hooks';
import { alertsSelectors } from '../redux/alerts';
import { alertsPositioning } from '../constants';

export function useSnackbarController() {
  const alert = useAppSelector(alertsSelectors.alert);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!alert.text || !alert.severity) return;

    enqueueSnackbar(alert.text, {
      variant: alert.severity,
      anchorOrigin: alertsPositioning,
    });
  }, [alert, enqueueSnackbar]);
}
