export const isExpired = (date) => {
  const expired = new Date() >= new Date(date);
  return expired;
};
export const expiresInOneWeek = (date) => {
  let currentDate = new Date()
  const oneWeekAgo = new Date(currentDate.getTime() - 604800000);
  const oneWeekFromNow = new Date(currentDate.getTime() + 604800000);
  const expires = new Date(date) >= oneWeekAgo && new Date(date) <= oneWeekFromNow && new Date() < new Date(date);

  return expires;
};