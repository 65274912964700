import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { roles } from '../constants';

const AdminRoutes = ({ isAllowed, loadingSession }) => {
  if (loadingSession) return null;
  if (!isAllowed) {
    return <Navigate to="/login" />;
  } else if (
    isAllowed.role === roles.supreme ||
    isAllowed.role === roles.admin ||
    isAllowed.role === roles.clientEnterprise
  ) {
    return <Outlet />;
  }
  return <Navigate to="/" />;
};

export default AdminRoutes;
