export const roles = {
  client: 'client',
  clientEnterprise: 'clientEnterprise',
  admin: 'admin',
  supreme: 'supreme',
};

export const rolenames = {
  client: 'Cliente',
  clientEnterprise: 'Grupo empresario',
  admin: 'Vendedor',
  supreme: 'Organizador',
}