import React, { useState } from 'react';
import Spinner from '../../../components/Spinner/Spinner';
import '../Auth.scss';
import { loginUser } from '../../../redux/auth';
import { useAppDispatch } from '../../../redux/hooks';
import { Link } from 'react-router-dom';

const AuthenticationDisplay = () => {
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    email: '',
    password: '',
    remember: false,
  });

  const handleOnChangeUser = (e) => {
    if (e.target.name === 'remember' && user.remember === true) {
      setUser({
        ...user,
        remember: false,
      });
    } else if (e.target.name === 'remember' && user.remember === false) {
      setUser({
        ...user,
        remember: true,
      });
    } else {
      setUser({
        ...user,
        [e.target.name]: e.target.value,
      });
    }
  };

  const authenticate = async (e) => {
    e.preventDefault()
    setLoading(true)
    await dispatch(loginUser(user)).unwrap()
    setLoading(false)
  }

  return (
    <>
      {loading ? <Spinner modal={true} /> : false}
      <div className="formContainer">
        <img src="/images/logo.png" />
        <form onSubmit={authenticate}>
          <h2>INICIAR SESIÓN</h2>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            onChange={handleOnChangeUser}
            type="email"
          />
          <label htmlFor="password">Contraseña</label>
          <div className="passwordDivWrapper">
            <input
              id="password"
              name="password"
              onChange={handleOnChangeUser}
              type={showPassword ? "text" : "password"}
            />
            {showPassword ?
              <i class="bi bi-eye-slash" onClick={() => setShowPassword(!showPassword)}></i>
              : <i class="bi bi-eye" onClick={() => setShowPassword(!showPassword)}></i>
            }
          </div>
          <div>
            <input
              id="remember"
              name="remember"
              onChange={handleOnChangeUser}
              type="checkbox"
            />
            <label htmlFor="remember">Recordarme</label>
          </div>

          <button className="btn btn-danger" >
            Iniciar Sesión
          </button>
        </form>
        <Link to="/password-recovery">
          <p className="text-center mt-2" style={{textDecoration: 'underline'}}>Olvidé mi contraseña</p>
        </Link>
      </div>
    </>
  );
};

export default AuthenticationDisplay;
