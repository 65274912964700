import { Link } from 'react-router-dom';
import Charts from './Charts';
import { roles } from '../../../constants/';
import { useAppSelector } from '../../../redux/hooks';
import { usersSelector } from '../../../redux/users';

const AdminDashboardOptions = () => {
  const user = useAppSelector(usersSelector.userData);

  return (
    <>
      <div className="card-group mb-3 g-3 row rowAdminOptions">
        <div className="card">
          <div className="card-body">
            <div>
              <h5 className="card-title text-center">
                Registrar un nuevo usuario
              </h5>
              {/* <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> */}
            </div>
            <div>
              <Link
                to="/nuevo-usuario"
                state={{ role: 'client' }}
                className="btn btn-success"
              >
                Registrar cliente
              </Link>
            </div>
          </div>
        </div>
      </div>
      {user.role === roles.supreme && (
        <div className="charts">
          <Charts />
        </div>
      )}
    </>
  );
};

export default AdminDashboardOptions;
