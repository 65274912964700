export const insuranceTypes = [
  {
    label: 'Flota',
    value: 'Flota',
  },
  {
    label: 'Automotor',
    value: 'Automotor',
  },
  {
    label: 'Motovehículo',
    value: 'Motovehículo',
  },
  {
    label: 'Ciclistas',
    value: 'Ciclistas',
  },
  {
    label: 'Seguro técnico',
    value: 'Seguro técnico',
  },
  {
    label: 'Hogar',
    value: 'Hogar',
  },
  {
    label: 'Integral de comercio',
    value: 'Integral de comercio',
  },
  {
    label: 'Accidentes personales',
    value: 'Accidentes personales',
  },
  {
    label: 'Vida',
    value: 'Vida',
  },
  {
    label: 'Caución (Personas)',
    value: 'Caución (Personas)',
  },
  {
    label: 'Mala praxis',
    value: 'Mala praxis',
  },
  {
    label: 'Vida obligatorio',
    value: 'Vida obligatorio',
  },
  {
    label: 'Vida colectivo',
    value: 'Vida colectivo',
  },
  {
    label: 'Ley contrato de trabajo',
    value: 'Ley contrato de trabajo',
  },
  {
    label: 'Caución (Empresas)',
    value: 'Caución (Empresas)',
  },
  {
    label: 'Integral de consorcio',
    value: 'Integral de consorcio',
  },
  {
    label: 'Todo riesgo operativo',
    value: 'Todo riesgo operativo',
  },
  {
    label: 'Responsabilidad civil',
    value: 'Responsabilidad civil',
  },
  {
    label: 'Transporte',
    value: 'Transporte',
  },
  {
    label: 'ART',
    value: 'ART',
  },
];
