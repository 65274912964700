import React, { useState } from 'react';
import { isExpired } from '../../../utils/isExpired';
import Spinner from '../../../components/Spinner/Spinner';
import { essentialsSelectors } from '../../../redux/essentials';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getPolizasStats } from '../../../redux/stats';
import { errorSideAlert } from '../../../redux/alerts';

export const StatsPolizas = ({ polizas }) => {
  const dispatch = useAppDispatch();

  const insuranceCompanies = useAppSelector(essentialsSelectors.insuranceCompanies);
  const insuranceTypes = useAppSelector(essentialsSelectors.insuranceTypes);

  const [filtering, setFiltering] = useState({ insuranceCompany: 'ALL', type: 'ALL' });
  const handleOnChangeFiltering = (e) => {
    setFiltering({ ...filtering, [e.target.name]: e.target.value });
  };

  const oneMonthAgoDate = new Date();
  oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() - 1);
  const twoMonthsAgoDate = new Date();
  twoMonthsAgoDate.setMonth(twoMonthsAgoDate.getMonth() - 1);

  const getPolizasReport = async ({ month1, year1, month2, year2 }) => {
    const query = `?month1=${month1}&year1=${year1}&month2=${month2}&year2=${year2}`;
    if (month1 === month2 && year1 === year2)
      return dispatch(errorSideAlert('Los meses y años no pueden ser iguales'));
    const res = await dispatch(getPolizasStats(query)).unwrap();
  };

  const months = [
    { value: 0, label: 'Enero' },
    { value: 1, label: 'Febrero' },
    { value: 2, label: 'Marzo' },
    { value: 3, label: 'Abril' },
    { value: 4, label: 'Mayo' },
    { value: 5, label: 'Junio' },
    { value: 6, label: 'Julio' },
    { value: 7, label: 'Agosto' },
    { value: 8, label: 'Septiembre' },
    { value: 9, label: 'Octubre' },
    { value: 10, label: 'Noviembre' },
    { value: 11, label: 'Diciembre' },
  ];

  const years = [
    { value: 2023, label: 2023 },
    { value: 2024, label: 2024 },
    { value: 2025, label: 2025 },
    { value: 2026, label: 2026 },
    { value: 2027, label: 2027 },
    { value: 2028, label: 2028 },
    { value: 2029, label: 2029 },
    { value: 2030, label: 2030 },
  ];

  const showReportModal = () => {
    withReactContent(Swal).fire({
      title: 'Reporte comparativo',
      html: (
        <div
          style={{
            overflow: 'hidden',
            height: '100%',
            width: '100%',
            paddingTop: '10px',
            display: 'flex',
            gap: '1rem',
          }}
        >
          <div style={{ width: '50%' }}>
            <label>
              Mes <span className="requiredFormField">*</span>
            </label>
            <select className="form-select" id="month1">
              {months.map((month) => (
                <option value={month.value}>{month.label}</option>
              ))}
            </select>
            <label>
              Año <span className="requiredFormField">*</span>
            </label>
            <select className="form-select" id="year1">
              {years.map((year) => (
                <option value={year.value}>{year.label}</option>
              ))}
            </select>
          </div>
          <div style={{ width: '50px', margin: 'auto' }}>
            <p>VS</p>
          </div>
          <div style={{ width: '50%' }}>
            <label>
              Mes <span className="requiredFormField">*</span>
            </label>
            <select className="form-select" id="month2">
              {months.map((month) => (
                <option value={month.value}>{month.label}</option>
              ))}
            </select>
            <label>
              Año <span className="requiredFormField">*</span>
            </label>
            <select className="form-select" id="year2">
              {years.map((year) => (
                <option value={year.value}>{year.label}</option>
              ))}
            </select>
          </div>
        </div>
      ),
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Descargar',
      confirmButtonColor: 'green',
      preConfirm: () =>
        getPolizasReport({
          month1: document.getElementById('month1').value,
          year1: document.getElementById('year1').value,
          month2: document.getElementById('month2').value,
          year2: document.getElementById('year2').value,
        }),
    });
  };

  return (
    <div style={{ width: '95%' }}>
      {!polizas.length ? (
        <Spinner text="Preparando los datos..." />
      ) : (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <button onClick={showReportModal} className="btn btn-primary mb-3">
              Descargar reporte
            </button>
          </div>
          <p className="mb-0 text-center">Las estadísticas están basadas en pólizas vigentes</p>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  <select
                    onChange={handleOnChangeFiltering}
                    name="insuranceCompany"
                    className="form-select"
                    style={{ width: 'max-content' }}
                  >
                    <option value="ALL">Todas</option>
                    {insuranceCompanies.map((company) => (
                      <option value={company.value}>{company.label}</option>
                    ))}
                  </select>
                </th>
                <th scope="col">
                  <select
                    onChange={handleOnChangeFiltering}
                    name="type"
                    className="form-select"
                    style={{ width: 'max-content' }}
                  >
                    <option value="ALL">Todos</option>
                    {insuranceTypes.map((type) => (
                      <option value={type.value}>{type.label}</option>
                    ))}
                  </select>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="col">Compañía de seguros</th>
                <th scope="col">Tipo</th>
                <th scope="col"></th>
              </tr>
              {insuranceCompanies.map((company, index) => {
                if (filtering.insuranceCompany !== 'ALL') {
                  if (filtering.insuranceCompany === company.value) {
                    return (
                      <tr key={company.label + index}>
                        <th>{company.label}</th>
                        <td>
                          {insuranceTypes.map((type) => {
                            const amount = polizas.filter(
                              (poliza) =>
                                poliza.insuranceCompany === company.value &&
                                poliza.polizaType === type.value &&
                                !isExpired(poliza.expiresAt) &&
                                !poliza.anulled,
                            ).length;
                            if (filtering.type !== 'ALL') {
                              if (filtering.type === type.label && amount)
                                return (
                                  <p key={company.label + type.label} className="mb-0">
                                    <b>{type.label}</b>: {amount}
                                  </p>
                                );
                            } else if (amount) {
                              return (
                                <p key={company.label + type.label} className="mb-0">
                                  <b>{type.label}</b>: {amount}
                                </p>
                              );
                            }
                          })}
                        </td>
                      </tr>
                    );
                  }
                } else
                  return (
                    <tr>
                      <th>{company.label}</th>
                      <td>
                        {insuranceTypes.map((type) => {
                          const amount = polizas.filter(
                            (poliza) =>
                              poliza.insuranceCompany === company.value &&
                              poliza.polizaType === type.value &&
                              !isExpired(poliza.expiresAt) &&
                              !poliza.anulled,
                          ).length;
                          if (filtering.type !== 'ALL') {
                            if (filtering.type === type.label && amount)
                              return (
                                <p key={company.label + type.label} className="mb-0">
                                  <b>{type.label}</b>: {amount}
                                </p>
                              );
                          } else if (amount) {
                            return (
                              <p key={company.label + type.label} className="mb-0">
                                <b>{type.label}</b>: {amount}
                              </p>
                            );
                          }
                        })}
                      </td>
                    </tr>
                  );
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
