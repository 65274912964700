export const medics = [
  {
    value: 'vanivivaldelli@hotmail.com',
    label: 'Vanina Valdelli'
  },
  {
    value: 'carmenmasello1@yahoo.com.ar',
    label: 'Carmen Masello'
  },
  {
    value: 'mmswidzinski@gmail.com',
    label: 'DRA. MARIA SWIDZINSKI'
  }
]

export const medicalProviders = [
  {
    value: 'administración@cmlaboral.com.ar',
    label: 'CM Laboral'
  },
]