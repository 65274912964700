import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Spinner from '../../../../components/Spinner/Spinner';
import { errors, rolenames, roles } from '../../../../constants';
import UserSiniestros from './components/UserSiniestros';
import UserPolizas from './components/UserPolizas';
import { states } from '../../../../constants';
import { FaCarCrash, FaClipboardList } from 'react-icons/fa';
import UserEnterprises from './components/UserEnterprises';
import { Chip } from '../../../../components/Chip/Chip';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { editUserById, getUserById, usersSelector } from '../../../../redux/users';
import { warningSideAlert } from '../../../../redux/alerts';
import { DetailChips } from '../../../../components/Chips/DetailChips';

const UserDetail = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(usersSelector.userData);

  const { uid } = useParams();

  const [userDetail, setUserDetail] = useState(false);
  const [userEdition, setUserEdition] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleGetUserData = async () => {
    const res = await dispatch(getUserById(uid)).unwrap();
    if (!res) return;
    setUserDetail(res);
    setUserEdition(res);
  };

  useEffect(() => {
    handleGetUserData();
  }, [uid]);

  const handleOnChangeEditProfile = (e) => {
    if (e.target.name.includes('address')) {
      let field = e.target.name.split('.')[1];
      setUserEdition({
        ...userEdition,
        address: {
          ...userEdition.address,
          [field]: e.target.value,
        },
      });
    } else if (e.target.name.includes('document')) {
      let field = e.target.name.split('.')[1];
      setUserEdition({
        ...userEdition,
        document: {
          ...userEdition.document,
          [field]: e.target.value,
        },
      });
    } else {
      setUserEdition({
        ...userEdition,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeUserStatus = async () => {
    const res = await dispatch(editUserById({ ...userEdition, status: !userEdition.status })).unwrap();

    if (res) {
      setUserDetail(res);
      setUserEdition(res);
    }

    setIsEditing(false);
    setIsLoading(false);
  }

  const handleEditUserData = async () => {
    setIsLoading(true);
    if (userDetail === userEdition) {
      dispatch(warningSideAlert('No has modificado ningún dato para editar'));
      return setIsLoading(false);
    }

    const res = await dispatch(editUserById(userEdition)).unwrap();

    if (res) {
      setUserDetail(res);
      setUserEdition(res);
    }

    setIsEditing(false);
    setIsLoading(false);
  };

  return (
    <>
      <h1>{user.role === roles.clientEnterprise ? 'Empresas' : 'Usuarios'}</h1>
      <div className="contentContainer">
        {userDetail ? (
          <>
            <DetailChips user={userDetail} />
            <h5 className="card-title mt-4 mb-2">{userDetail.socialReason}</h5>
            <div className="card text-center mb-3 mx-4 w-90">
              <div className="card-body">
                {isEditing ? (
                  <div
                    style={{
                      maxWidth: '350px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignContent: 'center',
                      margin: 'auto',
                    }}
                  >
                    <label>Role del usuario</label>
                    <select
                      onChange={handleOnChangeEditProfile}
                      className="form-select"
                      type="text"
                      name="role"
                    >
                      {Object.keys(roles).map((role, index) => {
                        return (
                          <option
                            selected={userEdition.role === role}
                            key={index}
                            value={role}
                          >
                            {rolenames[role]}
                          </option>
                        );
                      })}
                    </select>
                    <label>Razón social</label>
                    <input
                      value={userEdition.socialReason}
                      name="socialReason"
                      onChange={handleOnChangeEditProfile}
                      className="form-control"
                      type="text"
                    />
                    <label>Dirección de email</label>
                    <input
                      value={userEdition.email}
                      name="email"
                      onChange={handleOnChangeEditProfile}
                      className="form-control"
                      type="email"
                    />
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: 'calc(50% - 5px)',
                        }}
                      >
                        <label>Tipo de documento</label>
                        <select
                          name="document.documentType"
                          value={userEdition.document.documentType}
                          onChange={handleOnChangeEditProfile}
                          className="form-select"
                          type="text"
                          style={{ width: '100%' }}
                        >
                          <option
                            selected={
                              userEdition.document.documentType === 'CUIT'
                            }
                            value="CUIT"
                          >
                            CUIT
                          </option>
                          <option
                            selected={
                              userEdition.document.documentType === 'DNI'
                            }
                            value="DNI"
                          >
                            DNI
                          </option>
                        </select>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: 'calc(50% - 5px)',
                        }}
                      >
                        <label>Nº de Documento</label>
                        <input
                          name="document.documentNumber"
                          onChange={handleOnChangeEditProfile}
                          value={userEdition.document.documentNumber}
                          className="form-control"
                          type="text"
                          style={{ width: '100%' }}
                        />
                      </div>
                    </div>
                    <label>Número de teléfono</label>
                    <input
                      onChange={handleOnChangeEditProfile}
                      value={userEdition.phoneNumber || ''}
                      className="form-control"
                      name="phoneNumber"
                      type="number"
                    />
                    <label htmlFor="state">Provincia</label>
                    <select
                      onChange={handleOnChangeEditProfile}
                      name="address.state"
                      className="form-select"
                    >
                      <option value="">Selecciona una provincia</option>
                      {states.map((state, index) => {
                        let isSelected = userEdition.address.state === state;
                        return (
                          <option
                            selected={isSelected}
                            key={index}
                            value={state}
                          >
                            {state}
                          </option>
                        );
                      })}
                    </select>
                    <label>Localidad</label>
                    <input
                      onChange={handleOnChangeEditProfile}
                      value={userEdition.address.location}
                      name="address.location"
                      className="form-control"
                      type="text"
                    />
                    <label>Calle</label>
                    <input
                      onChange={handleOnChangeEditProfile}
                      value={userEdition.address.street}
                      name="address.street"
                      className="form-control"
                      type="text"
                    />
                    <label>Altura / Número</label>
                    <input
                      onChange={handleOnChangeEditProfile}
                      value={userEdition.address.number}
                      name="address.number"
                      className="form-control"
                      type="number"
                    />
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Piso</label>
                        <input
                          onChange={handleOnChangeEditProfile}
                          value={userEdition.address.floor}
                          name="address.floor"
                          className="form-control"
                          type="number"
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Nº de Departamento</label>
                        <input
                          onChange={handleOnChangeEditProfile}
                          value={userEdition.address.apartment}
                          name="address.apartment"
                          className="form-control"
                          type="text"
                          style={{ width: '100%' }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {user.role === roles.supreme && <p className="mb-0">
                      <b>Role: </b>
                      {userDetail.role && rolenames[userDetail.role]}
                    </p>}

                    <p className="mb-0">
                      <Link to={`mailto:${userDetail.email}`}>
                        {userDetail.email}
                      </Link>
                    </p>
                    <p className="mb-0">
                      <b>{userDetail.document.documentType}: </b>
                      {userDetail.document.documentNumber}
                    </p>
                    <p className="mb-0">
                      <b>Teléfono:</b> {userDetail.phoneNumber || ''}
                    </p>
                    <p className="mb-0">
                      <b>Provincia:</b> {userDetail.address.state}
                    </p>
                    <p className="mb-0">
                      <b>Localidad:</b> {userDetail.address.location}
                    </p>
                    <p className="mb-0">
                      <b>Dirección:</b> {userDetail.address.street}{' '}
                      {userDetail.address.number}, {userDetail.address.floor}{' '}
                      {userDetail.address.apartment}
                    </p>

                    {userDetail.assignedTo && (
                      <p className="mb-0">
                        <b>A cargo del vendedor:</b>{' '}
                        <Link to={`/usuarios/${userDetail.assignedTo._id}`}>
                          {userDetail.assignedTo.socialReason}
                        </Link>
                      </p>
                    )}
                  </>
                )}
                {user.role === roles.supreme ? (
                  !isEditing ? (
                    <button
                      className="btn btn-primary mt-4"
                      onClick={() => setIsEditing(true)}
                    >
                      Editar datos del usuario
                    </button>
                  ) : (
                    <>
                      <button
                        className="btn btn-success mt-4"
                        onClick={handleEditUserData}
                      >
                        {isLoading ? (
                          <div
                            className="spinner-border text-light"
                            role="status"
                          ></div>
                        ) : (
                          'Confirmar nuevos datos'
                        )}
                      </button>
                      <button
                        className="btn btn-danger mt-2"
                        onClick={() => setIsEditing(false)}
                      >
                        Cancelar
                      </button>
                    </>
                  )
                ) : null}
              </div>
            </div>

            {userDetail.role === roles.clientEnterprise && (
              <>
                <h5 className="card-title mt-2">
                  Mis empresas ({userDetail.myEnterprises.length})
                </h5>
                <UserEnterprises userEnterprises={userDetail.myEnterprises} />
              </>
            )}

            <h5 className="card-title mt-2">
              Pólizas ({userDetail.polizas.length})
            </h5>
            {user.role === roles.supreme && (
              <Link
                to="/polizas/cargar"
                state={{
                  userDetail: userDetail,
                }}
                className="mt-2 mb-2 btn btn-success w-90"
                style={{ maxWidth: '300px' }}
              >
                <FaClipboardList
                  style={{ marginRight: '8px', marginTop: '-2px' }}
                />
                Cargar nueva póliza
              </Link>
            )}
            <UserPolizas
              userPolizas={userDetail.polizas}
              userDetail={userDetail}
            />

            <h5 className="card-title mt-2">
              Siniestros ({userDetail.siniestros.length})
            </h5>

            {user.role === roles.supreme ? (
              <>
                <Link
                  to="/siniestros/reportar"
                  state={userDetail}
                  className="mt-2 mb-2 btn btn-warning w-90"
                  style={{ maxWidth: '300px' }}
                >
                  <FaCarCrash
                    style={{ marginRight: '8px', marginTop: '-2px' }}
                  />
                  Cargar nuevo siniestro
                </Link>
                <UserSiniestros userSiniestros={userDetail.siniestros} />
              </>
            ) : (
              false
            )}
          </>
        ) : (
          <Spinner />
        )}
      </div >
      {
        user.role === roles.supreme && userDetail ? (
          <>
            {userDetail.status ? (
              <button
                className="btn btn-danger mt-2 w-90"
                onClick={handleChangeUserStatus}
              >
                DESACTIVAR USUARIO
              </button>
            ) : (
              <button
                className="btn btn-SUCCESS mt-2 w-90"
                onClick={handleChangeUserStatus}
              >
                ACTIVAR USUARIO
              </button>
            )}
          </>
        ) : (
          false
        )
      }
    </>
  );
};

export default UserDetail;
