import { useAppSelector } from '../../redux/hooks'
import { usersSelector } from '../../redux/users'
import { roles } from '../../constants'
import { expiresInOneWeek, isExpired } from '../../utils/isExpired';
import { Chip } from './Chip';

export const CardChips = (props) => {
  const user = useAppSelector(usersSelector.userData);

  if (props.poliza)
    return (
      <div className="chipsWrapper">
        {user.role === roles.supreme &&
          expiresInOneWeek(props.poliza.expiresAt) ? (
          <Chip
            sx={{ marginBottom: '0.6rem' }}
            color="purple"
            label="Vence pronto"
          />
        )
          :
          isExpired(props.poliza.expiresAt) && (
            <Chip
              sx={{ marginBottom: '0.6rem' }}
              color="warning"
              label="Vencida"
            />
          )
        }
        {props.poliza.anulled && (
          <Chip
            sx={{ marginBottom: '0.6rem' }}
            color="danger"
            label="Anulada"
          />
        )}
        {props.poliza.renewed && (
          <Chip
            sx={{ marginBottom: '0.6rem' }}
            color="primary"
            label="Renovada"
          />
        )}
      </div>
    )
  else if (props.study)
    return (
      <div className="chipsWrapper">
        {props.study.invoiced && (
          <Chip
            color="success"
            label="Factrurado"
            sx={{ marginBottom: '0.6rem' }}
          />
        )}
        {props.study.fulfilled && (
          <Chip
            color="primary"
            label="Realizado"
            sx={{ marginBottom: '0.6rem' }}
          />
        )}
        {props.study.anulled && (
          <Chip
            color="danger"
            label="Anulado"
            sx={{ marginBottom: '0.6rem' }}
          />
        )}
      </div>
    )
  else if (props.siniestro)
    return (
      <div className="chipsWrapper">
        {!!props.siniestro.denunciaFiles.length && (
          <Chip
            sx={{ marginBottom: '0.6rem' }}
            color="success"
            label="Denuncia cargada"
          />
        )}
      </div>
    )
  else if (props.user)
    return (
      <div className="chipsWrapper">
        {!props.user.status ? (
          <Chip
            color="danger"
            label="Usuario desabilitado"
            sx={{ marginBottom: '0.6rem' }}
          />
        ) : (
          false
        )}
      </div>
    )
  else return;
}
