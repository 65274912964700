import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { roles } from '../../../constants';
import { FaCarCrash, FaClipboardList } from 'react-icons/fa';
import { Chip } from '../../../components/Chip/Chip';
import { useAppSelector } from '../../../redux/hooks';
import { usersSelector } from '../../../redux/users';
import { CardChips } from '../../../components/Chips/CardChips';

const UserCard = (props) => {
  const user = useAppSelector(usersSelector.userData);

  return (
    <div className={`card text-center mb-3 ${props.preview ? '' : 'w-90'}`}>
      <div className="card-body">
        <CardChips user={props.user} />
        <h5 className="card-title text-start">{props.user.socialReason}</h5>
        <h6 className="card-title text-start">
          {props.user.document.documentType} -{' '}
          {props.user.document.documentNumber}
        </h6>
        {!props.preview && <p className="card-text text-start">
          A cargo de: {props.user.assignedTo?.socialReason}
        </p>}
        {!props.preview && user.role === roles.supreme && (
          <div className="userCardReportButtons">
            <Link
              to="/polizas/cargar"
              state={{
                userDetail: props.user,
              }}
              className=" btn btn-success"
            >
              <FaClipboardList
                style={{ marginRight: '8px', marginTop: '-2px' }}
              />
              Cargar nueva póliza
            </Link>
            <Link
              to="/siniestros/reportar"
              state={props.user}
              className=" btn btn-warning"
            >
              <FaCarCrash style={{ marginRight: '8px', marginTop: '-2px' }} />
              Cargar nuevo siniestro
            </Link>
          </div>
        )}
        <Link
          target={props.preview ? '_blank' : ''}
          to={`/usuarios/${props.user._id}`}
          className="btn btn-primary mt-2"
        >
          Ver usuario
        </Link>
      </div>
      {!props.preview && <div className="card-footer text-body-secondary rowCardList row row-cols-sm-1 row-cols-md-1 row-cols-lg-2 ">
        <p className="mb-md-3 mb-lg-0 text-lg-start text-md-center">
          Pólizas: {props.user.polizas.length}
        </p>
        <p className="mb-0 text-lg-end text-md-center">
          Siniestros: {props.user.siniestros.length}
        </p>
      </div>}
    </div>
  );
};

export default UserCard;
