import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';
import UserSiniestros from '../Admin/UsersList/UserDetail/components/UserSiniestros';
import UserPolizas from '../Admin/UsersList/UserDetail/components/UserPolizas';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import UserEnterprises from '../Admin/UsersList/UserDetail/components/UserEnterprises';
import { roles } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUserById, usersSelector } from '../../redux/users';
import { warningSideAlert } from '../../redux/alerts';
import { changePassword } from '../../redux/auth';
import './Profile.scss';

const Profile = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(usersSelector.userData);

  const [userDetail, setUserDetail] = useState(false);

  const handleGetUserProfile = async () => {
    const res = await dispatch(getUserById(user._id)).unwrap();
    if (!res) return;
    setUserDetail(res);
  };

  useEffect(() => {
    handleGetUserProfile();
  }, []);

  const handleChangePassword = async (passwords) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    if (passwords.newPassword !== passwords.confirmNewPassword) dispatch(warningSideAlert('Las contraseñas no coinciden'));

    if (passwords.newPassword === '') dispatch(warningSideAlert('Por favor coloca tu nueva contraseña'));

    if (!passwordRegex.test(passwords.newPassword)) dispatch(warningSideAlert('La contraseña debe contener al menos una mayúscula, un número y un caracter especial (!@#$%^&*) y 8 de longitud'));

    dispatch(changePassword({ passwords }));
  };

  const showPasswordChangeModal = () => {
    withReactContent(Swal).fire({
      title: 'Cambio de contraseña',
      html: (
        <div
          style={{
            overflow: 'hidden',
            height: '100%',
            padding: '10px',
            width: '100%',
          }}
        >
          <label>
            Escribí tu contraseña actual <span className="requiredFormField">*</span>
          </label>
          <input
            id="currentPasswordInput"
            className="form-control mb-4"
            name="currentPassword"
            type="password"
          />
          <label>
            Nueva contraseña <span className="requiredFormField">*</span>
          </label>
          <input
            id="newPasswordInput"
            className="form-control mb-1"
            name="newPassword"
            type="password"
          />
          <label>
            Confirmá la nueva contraseña <span className="requiredFormField">*</span>
          </label>
          <input
            id="confirmNewPasswordInput"
            className="form-control mb-4"
            name="confirmNewPassword"
            type="password"
          />
          <p className="password-requirements mt-2 mb-1">La contraseña debe contener al menos:</p>
          <div className="password-requirements-wrapper" style={{ marginLeft: '20%' }}>
            <ul style={{ textAlign: 'start' }} className="password-requirements">
              <li>1 número</li>
              <li>1 mayúscula</li>
              <li>1 caracter especial (!@#$%^&*)</li>
              <li>Mínimo 8 caracteres</li>
            </ul>
          </div>
        </div>
      ),
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'green',
      preConfirm: () => {
        handleChangePassword({
          currentPassword: document.getElementById('currentPasswordInput').value,
          newPassword: document.getElementById('newPasswordInput').value,
          confirmNewPassword: document.getElementById('confirmNewPasswordInput').value,
        });
      },
    });
  };

  return (
    <>
      <h1>Mi cuenta</h1>
      <div className="contentContainer">
        {userDetail ? (
          <>
            <h5 className="card-title mt-4 mb-2">{userDetail.socialReason}</h5>
            <div className="card text-center mb-3 mx-4 w-90">
              <div className="card-body">
                <p className="mb-0">
                  <Link to={`mailto:${userDetail.email}`}>{userDetail.email}</Link>
                </p>
                <p className="mb-0">
                  <b>{userDetail.document.documentType}:</b> {userDetail.document.documentNumber}
                </p>
                <p className="mb-0">
                  <b>Teléfono:</b> {userDetail.phoneNumber || ''}
                </p>
                <p className="mb-0">
                  <b>Provincia:</b> {userDetail.address.state}
                </p>
                <p className="mb-0">
                  <b>Localidad:</b> {userDetail.address.location}
                </p>
                <p className="mb-0">
                  <b>Dirección:</b> {userDetail.address.street} {userDetail.address.number},{' '}
                  {userDetail.address.floor} {userDetail.address.apartment}
                </p>

                {userDetail.assignedTo && userDetail.role !== roles.client && (
                  <p className="mb-0">
                    <b>A cargo del vendedor: </b>
                    {userDetail.assignedTo.socialReason}
                  </p>
                )}
                <button className="btn btn-warning mt-2" onClick={showPasswordChangeModal}>
                  Cambiar contraseña
                </button>
              </div>
            </div>

            {userDetail.role === roles.clientEnterprise && (
              <>
                <h5 className="card-title mt-2">
                  Mis empresas ({userDetail.myEnterprises.length})
                </h5>
                <UserEnterprises userEnterprises={userDetail.myEnterprises} />
              </>
            )}

            <h5 className="card-title">Pólizas ({userDetail.polizas.length})</h5>

            <UserPolizas userPolizas={userDetail.polizas} />

            <h5 className="card-title">Siniestros ({userDetail.siniestros.length})</h5>

            <UserSiniestros userSiniestros={userDetail.siniestros} />
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export default Profile;
