import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { formatDateToYYYYMMDD } from '../../../utils/date';
import { complexUpdatePoliza } from '../../../redux/polizas';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { essentialsSelectors } from '../../../redux/essentials';

const EditPoliza = (props) => {
  const dispatch = useAppDispatch();

  const insuranceCompanies = useAppSelector(essentialsSelectors.insuranceCompanies);
  const insuranceTypes = useAppSelector(essentialsSelectors.insuranceTypes);

  const navigate = useNavigate();
  const { pid } = useParams();
  const [loading, setLoading] = useState(false);
  const [userToAssign, setUserToAssign] = useState({
    socialReason: '',
  });
  const [user, setUser] = useState({
    socialReason: '',
    email: '',
    document: {
      documentType: '',
      documentNumber: '',
    },
  });
  const [poliza, setPoliza] = useState({
    _id: '',
    userId: '',
    insuranceCompany: '',
    polizaNumber: '',
    polizaType: '',
    polizaPdf: '',
    endosos: [],
    domain: '',
    float: [],
    initializedAt: '',
    expiresAt: '',
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/polizas/id/${pid}`, {
        withCredentials: true,
      })
      .then((response) => {
        const polizaData = response.data;
        setUser({
          socialReason: polizaData.userId.socialReason,
          email: polizaData.userId.email,
          document: {
            documentType: polizaData.userId.document.documentType,
            documentNumber: polizaData.userId.document.documentNumber,
          },
        });
        setPoliza({
          _id: polizaData._id,
          userId: polizaData.userId._id,
          insuranceCompany: polizaData.insuranceCompany,
          polizaNumber: polizaData.polizaNumber,
          polizaType: polizaData.polizaType,
          polizaPdf: polizaData.polizaPdf,
          endosos: polizaData.endosos,
          domain: polizaData.domain,
          float: polizaData.float,
          initializedAt: polizaData.initializedAt,
          expiresAt: polizaData.expiresAt,
        });
        const documentType = polizaData.userId.document.documentType;
        const documentNumber = polizaData.userId.document.documentNumber;
        return axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/users/by-document?documentType=${documentType}&documentNumber=${documentNumber}`,
          {
            withCredentials: true,
          },
        );
      })
      .then((secondResponse) => {
        setUserToAssign({
          socialReason: secondResponse.data.assignedTo.socialReason,
        });
      })
      .catch((error) => {
        // showToast(error.response.data.message ?? errors.genericError, 'error');
      });
  }, [pid]);

  const handleOnChangePoliza = (e, index) => {
    const { name, value, files } = e.target;

    setPoliza((prevNewPoliza) => {
      if (name.startsWith('float')) {
        const updatedFloat = [...prevNewPoliza.float];
        if (!updatedFloat[index]) {
          updatedFloat[index] = { domain: '', tarjetaCirculacion: '' };
        }
        if (name.endsWith('domain')) {
          updatedFloat[index].domain = value;
        } else if (name.endsWith('tarjetaCirculacion') && files.length > 0) {
          updatedFloat[index].tarjetaCirculacion = files[0];
        }
        return { ...prevNewPoliza, domain: '', float: updatedFloat };
      } else {
        return { ...prevNewPoliza, [name]: value };
      }
    });
  };

  const handleRemove = (index) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este archivo?')) {
      const updatedFiles = poliza.polizaPdf.filter((_, i) => i !== index);
      setPoliza({ ...poliza, polizaPdf: updatedFiles });
    }
  };

  const handleAddFloat = () => {
    setPoliza((prevNewPoliza) => ({
      ...prevNewPoliza,
      float: [...prevNewPoliza.float, { domain: '', tarjetaCirculacion: '' }],
    }));
  };

  const handleRemoveFloat = (indexToRemove) => {
    setPoliza((prevNewPoliza) => {
      const updatedFloat = prevNewPoliza.float.filter(
        (_, index) => index !== indexToRemove,
      );
      return { ...prevNewPoliza, float: updatedFloat };
    });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    const data = poliza;

    formData.append('type', 'polizas');
    formData.append('data', JSON.stringify({ data }));

    const polizaPdfInput = document.querySelector('input[name="polizaPdf"]');
    if (polizaPdfInput) {
      const polizaPdfs = polizaPdfInput.files;
      for (let i = 0; i < polizaPdfs.length; i++) {
        const polizaPdf = polizaPdfs[i];
        formData.append('polizaPdf', polizaPdf);
      }
    }

    const endosoPdfInput = document.querySelector('input[name="endosos"]');
    if (endosoPdfInput) {
      const endosoPdfs = endosoPdfInput.files;
      for (let i = 0; i < endosoPdfs.length; i++) {
        const endosoPdf = endosoPdfs[i];
        formData.append('endosoPdf', endosoPdf);
      }
    }

    for (let i = 0; i < poliza.float.length; i++) {
      const flotaFilesInput = document.querySelector(
        `input[name="float${i}.tarjetaCirculacion"]`,
      );
      if (flotaFilesInput) {
        const flotaFiles = flotaFilesInput.files;
        if (flotaFiles.length > 0) {
          formData.append('flotaFiles', flotaFiles[0]);
        }
      }
    }

    const flotaFilesInput = document.querySelector('input[name="flotaFiles"]');
    if (flotaFilesInput) {
      const flotaFiles = flotaFilesInput.files;
      for (let i = 0; i < flotaFiles.length; i++) {
        formData.append('flotaFiles', flotaFiles[i]);
      }
    }

    const res = await dispatch(complexUpdatePoliza({ pid, formData })).unwrap();
    if (res) navigate(`/polizas/${pid}`);

    setLoading(false);
  };

  return (
    <>
      <h1>Editar Póliza</h1>
      <div className="contentContainer">
        <form className="newSiniestroForm  mt-3">
          <label>
            Asegurado <span className="requiredFormField">*</span>
          </label>
          <input
            className="form-control"
            name="socialReason"
            value={`${user.socialReason}`}
            disabled={true}
            type="text"
            required
          />
          <label>
            Email Asegurado <span className="requiredFormField">*</span>
          </label>
          <input
            className="form-control"
            name="email"
            value={`${user.email}`}
            disabled={true}
            type="text"
            required
          />
          <label>
            A cargo de <span className="requiredFormField">*</span>
          </label>
          <input
            className="form-control"
            name="email"
            value={userToAssign.socialReason}
            disabled={true}
            type="text"
            required
          />
        </form>
        <form onSubmit={handleOnSubmit}>
          <div
            style={{
              width: '100%   ',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <label style={{ width: '300px' }}>
              Compañia <span className="requiredFormField">*</span>
            </label>
            <select
              style={{ width: '300px' }}
              name="insuranceCompany"
              onChange={handleOnChangePoliza}
              className="form-select"
              value={poliza.insuranceCompany}
              required
            >
              {insuranceCompanies.map((insuranceC, index) => {
                return (
                  <option
                    key={`insuranceC${index}`}
                    value={`${insuranceC.value}`}
                  >
                    {insuranceC.label}
                  </option>
                );
              })}
            </select>

            <label style={{ width: '300px' }}>
              Tipo de Póliza <span className="requiredFormField">*</span>
            </label>
            <select
              style={{ width: '300px' }}
              name="polizaType"
              onChange={handleOnChangePoliza}
              className="form-select"
              value={poliza.polizaType}
              required
            >
              <option value={null}>Seleccione el tipo de póliza</option>
              {insuranceTypes.map((insuranceT, index) => {
                return (
                  <option
                    key={`insuranceT${index}`}
                    value={`${insuranceT.value}`}
                  >
                    {insuranceT.label}
                  </option>
                );
              })}
            </select>
            <label style={{ width: '300px' }}>
              Numero de Póliza <span className="requiredFormField">*</span>
            </label>
            <input
              style={{ width: '300px' }}
              className="form-control"
              name="polizaNumber"
              onChange={handleOnChangePoliza}
              type="text"
              value={poliza.polizaNumber}
              required
            />
            <label>
              Fecha de alta de la póliza{' '}
              <span className="requiredFormField">*</span>
            </label>
            <input
              style={{ width: '300px' }}
              type="date"
              className="form-control"
              id="fechaInicializada"
              name="initializedAt"
              value={
                poliza.initializedAt
                  ? formatDateToYYYYMMDD(poliza.initializedAt)
                  : ''
              }
              onChange={handleOnChangePoliza}
              required
            ></input>
            <label>
              Fecha de expiración <span className="requiredFormField">*</span>
            </label>
            <input
              style={{ width: '300px' }}
              type="date"
              className="form-control"
              id="fechaExpiración"
              name="expiresAt"
              value={
                poliza.expiresAt ? formatDateToYYYYMMDD(poliza.expiresAt) : ''
              }
              onChange={handleOnChangePoliza}
              required
            ></input>
          </div>

          {(() => {
            switch (poliza.polizaType) {
              case 'Flota':
                return (
                  <>
                    <p className="mt-3 text-center">
                      PDF póliza<span className="requiredFormField">*</span>{' '}
                    </p>
                    {poliza.polizaPdf && poliza.polizaPdf.length > 0 ? (
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '0 20px',
                            gap: '20px',
                          }}
                        >
                          {poliza.polizaPdf.map((filePath, pdfIndex) => (
                            <React.Fragment key={`pdfFragment-${pdfIndex}`}>
                              {' '}
                              {(() => {
                                const modifiedPdfPath = filePath.slice(
                                  filePath.indexOf('/src/') + 5,
                                );
                                const pdfExtension = modifiedPdfPath
                                  .split('.')
                                  .pop()
                                  .toLowerCase();

                                return pdfExtension === 'pdf' ? (
                                  <embed
                                    src={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPdfPath}`}
                                    type="application/pdf"
                                    width="45%"
                                    height="auto"
                                  />
                                ) : (
                                  <img
                                    src={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPdfPath}`}
                                    alt=""
                                    style={{ width: '45%', height: 'auto' }}
                                  />
                                );
                              })()}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <input
                        className="form-control"
                        name="polizaPdf"
                        type="file"
                        accept=".pdf, image/jpeg, image/png, image/gif"
                        multiple={true}
                        required
                      />
                    )}
                    <br />
                    <h5 className="card-title text-center">
                      Añadir vehículos a la flota
                    </h5>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        padding: '10px 20px',
                        gap: '20px',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      {poliza.float.map((flota, index) => {
                        const modifiedPath = flota.tarjetaCirculacion.slice(
                          flota.tarjetaCirculacion.indexOf('/src/') + 5,
                        );
                        return (
                          <div
                            key={index}
                            className="card text-center"
                            style={{ width: '200px' }}
                          >
                            <div className="card-body">
                              <h5>Vehículo {index + 1}</h5>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'baseline',
                                }}
                              >
                                <label className="mt-3">
                                  Dominio{' '}
                                  <span className="requiredFormField">*</span>{' '}
                                </label>
                                <img
                                  src="/images/delete.png"
                                  style={{ width: '15px', height: '15px' }}
                                  alt="Eliminar"
                                  className="deleteIcon"
                                  onClick={() => handleRemoveFloat(index)}
                                />
                              </div>
                              <input
                                className="form-control"
                                name={`float.${index}.domain`}
                                type="text"
                                placeholder="Dominio"
                                value={flota.domain}
                                onChange={(e) => handleOnChangePoliza(e, index)}
                                key={`floatDomainInput-${index}`}
                                required
                              />
                              {flota.tarjetaCirculacion ? (
                                <a
                                  target="_blank"
                                  href={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPath}`}
                                  className="btn btn-success mt-2"
                                >
                                  Ver tarjeta de circulación
                                </a>
                              ) : (
                                <input
                                  className="form-control"
                                  name={`float${index}.tarjetaCirculacion`}
                                  type="file"
                                  accept=".pdf, image/jpeg, image/png, image/gif"
                                  multiple={false}
                                  required
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        width: '100%   ',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <h5 className="card-title mt-2">
                        Añade un nuevo vehículo a la flota
                      </h5>
                      <button
                        type="button"
                        style={{ width: '300px' }}
                        className="btn btn-info mt-1 mb-2"
                        onClick={handleAddFloat}
                      >
                        +
                      </button>
                    </div>
                  </>
                );
              case 'Automotor':
                return (
                  <>
                    <label>
                      Dominio <span className="requiredFormField">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="domain"
                      placeholder="Ingresá acá el dominio"
                      onChange={(e) => handleOnChangePoliza(e)}
                      type="text"
                      value={poliza.domain}
                      required
                    />
                    {poliza.polizaPdf?.map((filePath, index) => {
                      const modifiedPath = filePath.slice(
                        filePath.indexOf('/src/') + 5,
                      );
                      const fileExtension = modifiedPath
                        .split('.')
                        .pop()
                        .toLowerCase();
                      return (
                        <div key={index}>
                          <p className="mt-3 mb-0">Archivo {index + 1}:</p>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            {fileExtension === 'pdf' ? (
                              <embed
                                src={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPath}`}
                                type="application/pdf"
                                width="100%"
                                height="auto"
                              />
                            ) : (
                              <img
                                src={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPath}`}
                                alt=""
                                style={{ width: '100%', height: 'auto' }}
                              />
                            )}
                            <button
                              className="btn btn-danger mt-1"
                              onClick={() => handleRemove(index)}
                            >
                              Eliminar documento
                            </button>
                            <Link
                              to={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPath}`}
                              className="btn btn-success mt-1 mb-3"
                              target="_blank"
                            >
                              Ver documento
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                    <label className="mt-3">
                      PDF póliza y tarjeta de circulación
                    </label>
                    <input
                      className="form-control"
                      name="polizaPdf"
                      type="file"
                      accept=".pdf, image/jpeg, image/png, image/gif"
                      multiple
                    />
                  </>
                );
              default:
                return (
                  <>
                    {poliza.polizaPdf
                      ? poliza?.polizaPdf?.map((filePath, index) => {
                        const modifiedPath = filePath.slice(
                          filePath.indexOf('/src/') + 5,
                        );
                        const fileExtension = modifiedPath
                          .split('.')
                          .pop()
                          .toLowerCase();
                        return (
                          <div key={index}>
                            <p className="mt-3 mb-0">Archivo {index + 1}:</p>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {fileExtension === 'pdf' ? (
                                <embed
                                  src={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPath}`}
                                  type="application/pdf"
                                  width="100%"
                                  height="auto"
                                />
                              ) : (
                                <img
                                  src={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPath}`}
                                  alt=""
                                  style={{ width: '100%', height: 'auto' }}
                                />
                              )}
                              <button
                                className="btn btn-danger mt-1"
                                onClick={() => handleRemove(index)}
                              >
                                Eliminar documento
                              </button>
                              <Link
                                to={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPath}`}
                                className="btn btn-success mt-1 mb-3"
                                target="_blank"
                              >
                                Ver documento
                              </Link>
                            </div>
                          </div>
                        );
                      })
                      : false}
                    <label className="mt-3">PDF(s) póliza</label>
                    <input
                      className="form-control"
                      name="polizaPdf"
                      type="file"
                      accept=".pdf, image/jpeg, image/png, image/gif"
                      multiple
                    />
                  </>
                );
            }
          })()}

          <div
            style={{
              width: '100%   ',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <label style={{ width: '300px' }} className="mt-3">
              PDF Endoso
            </label>
            <input
              style={{ width: '300px' }}
              className="form-control"
              name="endosos"
              type="file"
              accept=".pdf, image/jpeg, image/png, image/gif"
              multiple
            />
            <button
              style={{ width: '300px' }}
              type="submit"
              className="btn btn-primary mt-3"
              disabled={loading}
            >
              {loading ? (
                <div className="spinner-border text-light" role="status"></div>
              ) : (
                'Editar póliza'
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditPoliza;
