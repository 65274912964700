import React, { useEffect } from 'react';
import { FaUnlink } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const NotFound404 = ({ user }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate('/login');
  }, [user]);

  if (!user) return;

  return (
    <>
      <h1 className="text-center">S&A Seguros</h1>
      <div className="contentContainer notFound404">
        <h2 className="text-center mt-3">PAGINA NO ENCONTRADA</h2>
        <FaUnlink />
        <h2 className="text-center">Lamentamos el inconveniente</h2>
      </div>
    </>
  );
};

export default NotFound404;
