import {
  FaBriefcaseMedical,
  FaCarCrash,
  FaClipboardList,
  FaTh,
  FaUserPlus,
  FaUsers,
  FaChartBar
} from 'react-icons/fa';

export const clientSidebar = [
  {
    path: '/',
    name: 'Panel',
    icon: <FaTh />,
  },
  {
    path: '/polizas?page=1&limit=20',
    name: 'Pólizas',
    icon: <FaClipboardList />,
  },
  {
    path: '/siniestros?page=1&limit=20',
    name: 'Siniestros',
    icon: <FaCarCrash />,
  },
];

export const clientEnterpiseSidebar = [
  {
    path: '/',
    name: 'Panel',
    icon: <FaTh />,
  },
  {
    path: '/usuarios?page=1&limit=20',
    name: 'Empresas',
    icon: <FaUsers />,
  },
  {
    path: '/polizas?page=1&limit=20',
    name: 'Pólizas',
    icon: <FaClipboardList />,
  },
  {
    path: '/siniestros?page=1&limit=20',
    name: 'Siniestros',
    icon: <FaCarCrash />,
  },
];

export const adminSidebar = [
  {
    path: '/',
    name: 'Panel',
    icon: <FaTh />,
  },
  {
    path: '/usuarios?page=1&limit=20',
    name: 'Usuarios',
    icon: <FaUsers />,
  },
  {
    path: '/polizas?page=1&limit=20',
    name: 'Pólizas',
    icon: <FaClipboardList />,
  },
];

export const supremeSidebar = [
  {
    path: '/',
    name: 'Panel',
    icon: <FaTh />,
  },
  {
    path: '/nuevo-usuario',
    name: 'Nuevo usuario',
    icon: <FaUserPlus />,
  },
  {
    path: '/usuarios?page=1&limit=20',
    name: 'Usuarios',
    icon: <FaUsers />,
  },
  {
    path: '/polizas?page=1&limit=20',
    name: 'Pólizas',
    icon: <FaClipboardList />,
  },
  {
    path: '/siniestros?page=1&limit=20',
    name: 'Siniestros',
    icon: <FaCarCrash />,
  },
  {
    path: '/medicina-laboral?page=1&limit=20',
    name: 'Medicina laboral',
    icon: <FaBriefcaseMedical />,
  },
  {
    path: '/estadisticas?tab=usuarios',
    name: 'Estadísticas',
    icon: <FaChartBar />,
  }
];