import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { states } from '../../../constants';
import RadioInputSelector from '../../../components/RadioInputSelector/RadioInputSelector';
import Spinner from '../../../components/Spinner/Spinner';
import { formatDateToYYYYMMDD } from '../../../utils/date';
import { useAppDispatch } from '../../../redux/hooks';
import { getStudyDetail, updateStudy } from '../../../redux/studies';

const EditStudy = () => {
  const dispatch = useAppDispatch();

  const { studyId } = useParams()

  const [study, setStudy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMedic, setIsMedic] = useState(false);

  const navigate = useNavigate()

  const getStudy = async () => {
    const res = await dispatch(getStudyDetail(studyId)).unwrap();
    if (!res) return;

    setStudy(res);
  }

  useEffect(() => {
    getStudy()
  }, [studyId]);

  useEffect(() => {
    if (study.medic) {
      setIsMedic(true)
    }
  }, [study]);

  const handleOnSubmit = async () => {
    setLoading(true);

    let auxStudy = { ...study }
    if (isMedic) {
      auxStudy.clinic = '';
    } else {
      auxStudy.medic = '';
    }

    setStudy(auxStudy);

    const res = await dispatch(updateStudy({ studyId, formData: auxStudy })).unwrap();
    setLoading(false);
    if (!res) return;
    navigate(`/medicina-laboral/${res._id}`);
  }

  const handleOnChange = (e) => {
    setStudy({
      ...study,
      [e.target.name]: e.target.value
    });
  }

  const onChangePatient = (e, index) => {
    const patients = [...study.patients];
    patients[index][e.target.name] = e.target.value;
    setStudy({
      ...study,
      patients,
    });
  };

  const deletePatient = (indexToRemove) => {
    setStudy((prevStudy) => {
      const updatedPatients = prevStudy.patients.filter(
        (_, index) => index !== indexToRemove,
      );
      return { ...prevStudy, patients: updatedPatients };
    });
  };

  const addPatient = () => {
    setStudy({
      ...study,
      patients: [...(study.patients || []), { socialReason: '', dni: '' }],
    });
  };

  if (!study) return <Spinner />

  return (
    <>
      <h1>Editar Solicitud de Estudios</h1>
      <div className="contentContainer ">
        <div
          className='newSiniestroForm'
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <label style={{ width: '300px' }}>
            Solicitante <span className="requiredFormField">*</span>
          </label>
          <input
            style={{ width: '300px' }}
            className="form-control"
            name="orderedBy"
            onChange={handleOnChange}
            type="text"
            value={study.orderedBy}
            required
          />
          <RadioInputSelector
            title="Seleccione una opción"
            setState={setIsMedic}
            state={isMedic}
            confirm="Médico"
            deny="Prestador"
            required
          />
          {isMedic ? (
            <>
              <label style={{ width: '300px' }}>
                Nombre y apellido del médico{' '}
                <span className="requiredFormField">*</span>
              </label>
              <input
                name="medic"
                value={study.medic || ''}
                type="text"
                className="form-control"
                onChange={handleOnChange}
              />
            </>
          ) : (
            <>
              <label style={{ width: '300px' }}>
                Prestador <span className="requiredFormField">*</span>
              </label>
              <input
                name="clinic"
                value={study.clinic || ''}
                type="text"
                className="form-control"
                onChange={handleOnChange}
              />
            </>
          )}
          <label style={{ width: "100%" }}>
            Estudio médico / servicio a realizar{' '}
            <span className="requiredFormField">*</span>
          </label>
          <input
            type="text"
            className="form-control mb-2"
            name="type"
            value={study.type || ''}
            onChange={handleOnChange}
            required
          />

        </div>
        <h5 className="mb-0 mt-2">Pacientes</h5>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
            gap: '15px',
            flexWrap: 'wrap',
          }}
        >
          {!!study.patients?.length &&
            study.patients.map((patient, index) => {
              return (
                <div key={index} className="card" style={{ padding: '0.5rem' }}>
                  <label className="mt-0">
                    Nombre y apellido <span className="requiredFormField">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="socialReason"
                    value={patient.socialReason || ''}
                    onChange={(e) => onChangePatient(e, index)}
                    required
                  />
                  <label>
                    DNI<span className="requiredFormField">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="dni"
                    value={patient.dni || ''}
                    onChange={(e) => onChangePatient(e, index)}
                    required
                  />
                  <label>
                    Teléfono
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="phone"
                    value={patient.phone || ''}
                    onChange={(e) => onChangePatient(e, index)}
                    required
                  />
                  <label className="mt-1" style={{ width: "100%" }}>
                    Fecha de turno <span className="requiredFormField">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control mb-2"
                    name="date"
                    value={formatDateToYYYYMMDD(patient.date)}
                    onChange={(e) => onChangePatient(e, index)}
                    required={true}
                  />
                  <label className="mt-1" style={{ width: "100%" }}>
                    Horario
                  </label>
                  <input type="time" className="form-control mb-2" name="time" value={patient.time} onChange={(e) => onChangePatient(e, index)} />
                  <button
                    className="btn btn-danger mt-2"
                    type="button"
                    onClick={() => deletePatient(index)}
                  >
                    Eliminar
                  </button>
                </div>
              );
            })}
        </div>
        <p className="text-center mb-0 mt-2">Añadir paciente</p>
        <button className="btn btn-info" onClick={addPatient} style={{ maxWidth: "300px", width: "100%" }} type="button">
          +
        </button>
        <div style={{ maxWidth: "300px", width: "100%" }}>
          <label>Observaciones</label>
          <textarea
            className="form-control"
            rows="3"
            name="details"
            value={study.details}
            onChange={handleOnChange}
          ></textarea>
          <label>
            Provincia <span className="requiredFormField">*</span>
          </label>
          <select
            onChange={handleOnChange}
            name="state"
            className="form-select"
          >
            <option value="">Selecciona una provincia</option>
            {states.map((state, index) => {
              let selected;
              if (state === study.state) selected = true
              return (
                <option defaultValue={selected ? true : false} key={index} value={state}>
                  {state}
                </option>
              );
            })}
          </select>
        </div>
        <div
          style={{
            width: '100%   ',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <button
            style={{ width: '300px' }}
            type="submit"
            className="btn btn-primary mt-3"
            disabled={loading}
            onClick={handleOnSubmit}
          >
            {loading ? (
              <div className="spinner-border text-light" role="status"></div>
            ) : (
              'Editar solicitud de estudio'
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default EditStudy;