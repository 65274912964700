import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/Spinner/Spinner';
import { formatearFecha } from '../../../utils/date';
import { roles } from '../../../constants';
import UserSiniestros from '../../Admin/UsersList/UserDetail/components/UserSiniestros';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { usersSelector } from '../../../redux/users';
import { getPolizaDetail, polizasSelectors, simpleUpdatePoliza } from '../../../redux/polizas';
import { DetailChips } from '../../../components/Chips/DetailChips';
import '../Polizas.scss';

const PolizaDetail = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(usersSelector.userData);

  const loadingPoliza = useAppSelector(polizasSelectors.isLoading);

  const { pid } = useParams();
  const [poliza, setPoliza] = useState(null);
  const [floatVehicles, setFloatVechicles] = useState([]);
  const [floatVehicleToSearch, setFloatVehicleToSearch] = useState('');
  const [downloadingFile, setDownloadingFile] = useState(false);

  const handleGetPolizaDetail = async () => {
    const res = await dispatch(getPolizaDetail(pid)).unwrap();
    if (!res) return;

    setPoliza(res);
    setFloatVechicles(res.float);
  };

  useEffect(() => {
    handleGetPolizaDetail();
  }, [pid]);

  const downloadFile = (imageUrl, polizaNumber) => {
    setDownloadingFile(imageUrl, polizaNumber);
    const fileName = imageUrl.split('.').pop(); // Obtener el nombre del archivo dinámicamente

    fetch(`${process.env.REACT_APP_BACKEND_URL}/${imageUrl}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const enlace = document.createElement('a');
        enlace.href = url;
        enlace.setAttribute('download', `poliza-${polizaNumber}.${fileName}`);
        document.body.appendChild(enlace);
        enlace.click();
        document.body.removeChild(enlace);
        setDownloadingFile(false);
      })
      .catch((error) => {
        // showToast(error.response.data.message ?? errors.genericError, 'error');
        setDownloadingFile(false);
      });
  };

  const handleChangeFloatVehicleDomain = (e) => {
    setFloatVehicleToSearch(e.target.value);
    let auxFloat = poliza.float.filter((floatVehicle) => {
      return floatVehicle.domain.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setFloatVechicles(auxFloat);
  };

  const handleAnullePoliza = async () => {
    const res = await dispatch(simpleUpdatePoliza(poliza)).unwrap();
    if (!res) return;

    setPoliza({
      ...poliza,
      anulled: !poliza.anulled,
    });
  };

  if (loadingPoliza)
    return (
      <>
        <h1>Detalle de póliza</h1>
        <div className="card text-center mb-3 mx-4  pb-4" style={{ width: '100%' }}>
          <Spinner />
        </div>
      </>
    );

  return (
    <>
      <h1>Detalle de póliza</h1>
      <div className="card text-center mb-3 mx-4  pb-4" style={{ width: '100%' }}>
        {poliza && (
          <>
            <div className="card-body">
              <DetailChips poliza={poliza} />
              <h5 className="card-title mt-2">{poliza.insuranceCompany}</h5>
              <p className="mb-0">
                <b>Póliza a nombre de:</b>{' '}
                <Link to={`/usuarios/${poliza.userId._id}`}>{poliza.userId.socialReason}</Link>
              </p>
              <p className="mb-0">
                <b>Póliza de tipo:</b> {poliza.polizaType}
              </p>
              <p className="mb-0">
                <b>Número de póliza:</b> {poliza.polizaNumber}
              </p>
              {poliza.domain && poliza.domain !== '' && (
                <p className="mb-0">
                  <b>Dominio:</b> {poliza.domain}
                </p>
              )}
              <p className="mb-0">
                <b>{new Date() >= new Date(poliza.expiresAt) ? 'Vencida el:' : 'Vence el:'}</b>{' '}
                {formatearFecha(new Date(poliza.expiresAt))}
              </p>
            </div>
            <h5 className="card-title mt-4">
              Póliza {poliza.polizaPdf.length > 1 && 'y Tarjeta de circulación'}
            </h5>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                objectFit: 'contain',
                flexWrap:'wrap',
                gap: '20px',
              }}
            >
              {poliza.polizaPdf.map((polizaDoc, index) => {
                const filePath = polizaDoc;
                const modifiedPdfPath = filePath.slice(filePath.indexOf('/src/') + 5);
                let downloading = false;
                if (modifiedPdfPath === downloadingFile) downloading = true;
                return (
                  <div key={index} className="pdfContainerWrapper">
                    <embed
                      src={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPdfPath}`}
                      type="application/pdf"
                      height="500px"
                    />
                    <Link
                      target="_blank"
                      to={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPdfPath}`}
                      className="btn btn-success mt-2"
                    >
                      Ver documento
                    </Link>
                    <button
                      className="btn btn-primary mt-2"
                      onClick={() => downloadFile(modifiedPdfPath, poliza.polizaNumber)}
                    >
                      {downloading ? (
                        <div className="spinner-border text-light" role="status"></div>
                      ) : (
                        'Descargar'
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
            {poliza.tarjetaCirculacionPdf.length ? (
              <>
                <h5 className="card-title mt-4">Tarjeta de circulación</h5>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    objectFit: 'contain',
                    gap: '20px',
                  }}
                >
                  {poliza.tarjetaCirculacionPdf.map((tarjetaCirculacionDoc, index) => {
                    const filePath = tarjetaCirculacionDoc;
                    const modifiedPdfPath = filePath.slice(filePath.indexOf('/src/') + 5);
                    let downloading = false;
                    if (modifiedPdfPath === downloadingFile) downloading = true;
                    return (
                      <div key={index} className="pdfContainerWrapper">
                        <embed
                          src={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPdfPath}`}
                          type="application/pdf"
                          height="500px"
                        />
                        <Link
                          target="_blank"
                          to={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPdfPath}`}
                          className="btn btn-success mt-2"
                        >
                          Ver documento
                        </Link>
                        <button
                          className="btn btn-primary mt-2"
                          onClick={() => downloadFile(modifiedPdfPath, poliza.polizaNumber)}
                        >
                          {downloading ? (
                            <div className="spinner-border text-light" role="status"></div>
                          ) : (
                            'Descargar'
                          )}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              false
            )}
            {poliza.polizaType === 'Flota' && (
              <>
                <h5 className="card-title mt-4">Vehículos de la flota</h5>
                <input
                  style={{ width: '300px', margin: 'auto' }}
                  className="form-control mb-2"
                  placeholder="Filtra por dominio"
                  name="floatVehicleDomain"
                  type="text"
                  value={floatVehicleToSearch}
                  onChange={(e) => handleChangeFloatVehicleDomain(e)}
                />
                <div
                  style={{
                    display: 'flex',
                    padding: '10px 20px',
                    gap: '20px',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  {floatVehicles.length ? (
                    floatVehicles.map((floatVehicle, index) => {
                      const filePath = floatVehicle.tarjetaCirculacion;
                      let downloading = false;
                      const modifiedPdfPath = filePath.slice(filePath.indexOf('/src/') + 5);
                      if (modifiedPdfPath === downloadingFile) downloading = true;
                      return (
                        <div
                          key={floatVehicle.domain}
                          className="card text-center"
                          style={{ width: '200px' }}
                        >
                          <div className="card-body">
                            <h6 className="card-title">Vehículo {index + 1}</h6>
                            <p className="text-center">
                              Dominio: <b>{floatVehicle.domain}</b>
                            </p>
                            <a
                              target="_blank"
                              href={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPdfPath}`}
                              className="btn btn-success"
                            >
                              Ver tarjeta de circulación
                            </a>
                            <button
                              className="btn btn-primary mt-2"
                              onClick={() => downloadFile(modifiedPdfPath, poliza.polizaNumber)}
                            >
                              {downloading ? (
                                <div className="spinner-border text-light" role="status"></div>
                              ) : (
                                'Descargar'
                              )}
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-center alertMessage">
                      No hay vehículos con ese dominio en esta póliza
                    </p>
                  )}
                </div>
              </>
            )}

            <h5 className="card-title mt-4">Endosos de la póliza</h5>
            <div
              style={{
                display: 'flex',
                padding: '10px 20px',
                gap: '20px',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {poliza.endosos.length ? (
                poliza.endosos.map((endosoItem, index) => {
                  const filePath = endosoItem.endoso;
                  const modifiedPdfPath = filePath.slice(filePath.indexOf('/src/') + 5);
                  let downloading = false;
                  const fechaDeCarga = endosoItem.date ? new Date(endosoItem.date) : false;
                  const fechaDeCargaDeEndoso = fechaDeCarga ? formatearFecha(fechaDeCarga) : '';
                  if (modifiedPdfPath === downloadingFile) downloading = true;
                  return (
                    <div
                      key={`endoso${index}`}
                      className="card text-center"
                      style={{ width: '280px' }}
                    >
                      <div className="card-body">
                        <h6 className="card-title">Endoso #{index + 1}</h6>
                        <a
                          target="_blank"
                          href={`${process.env.REACT_APP_BACKEND_URL}/${modifiedPdfPath}`}
                          className="btn btn-success"
                        >
                          Ver endoso
                        </a>
                        <button
                          className="btn btn-primary mt-2"
                          onClick={() => downloadFile(modifiedPdfPath, poliza.polizaNumber)}
                        >
                          {downloading ? (
                            <div className="spinner-border text-light" role="status"></div>
                          ) : (
                            'Descargar'
                          )}
                        </button>
                      </div>
                      <div className="card-footer text-body-secondary ">
                        <p className="mb-0 text-center">
                          <b>Fecha de carga:</b>
                        </p>
                        <p className="mb-0 text-center">{fechaDeCargaDeEndoso}</p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>Esta póliza se encuentra sin endosos actualmente </p>
              )}
            </div>
            <h5 className="card-title mt-4">Siniestros relacionados a la póliza</h5>
            <div
              style={{
                display: 'flex',
                padding: '10px 20px',
                gap: '20px',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {poliza.relatedSiniestros.length ? (
                <UserSiniestros userSiniestros={poliza.relatedSiniestros} />
              ) : (
                <p>Esta póliza no tiene siniestros relacionados</p>
              )}
              {user.role === roles.supreme && (
                <>
                  {!poliza.anulled ? (
                    <button className="btn btn-danger mt-4 w-90" onClick={handleAnullePoliza}>
                      ANULAR PÓLIZA
                    </button>
                  ) : (
                    <button className="btn btn-success mt-4 w-90" onClick={handleAnullePoliza}>
                      DES-ANULAR PÓLIZA
                    </button>
                  )}
                  <Link to={`/polizas/editar/${pid}`} className=" w-90 btn btn-primary">
                    EDITAR
                  </Link>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PolizaDetail;
