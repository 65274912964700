import { createSlice } from '@reduxjs/toolkit';

// severity: 'success' | 'warning' | 'error' | null;
// text: string | null;
const initialState = {
  alert: {
    severity: null,
    text: null,
  },
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    warningSideAlert: (state, action) => {
      state.alert = {
        severity: 'warning',
        text: action.payload,
      };
    },
    successSideAlert: (state, action) => {
      state.alert = {
        severity: 'success',
        text: action.payload,
      };
    },
    errorSideAlert: (state, action) => {
      state.alert = {
        severity: 'error',
        text: action.payload,
      };
    },
  },
});

export const { warningSideAlert, successSideAlert, errorSideAlert } = alertsSlice.actions;
