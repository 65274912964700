import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { MobileMenu } from '../MobileMenu/MobileMenu';
import { useAppSelector } from '../../redux/hooks';
import { usersSelector } from '../../redux/users';

import './Navbar.scss';
const Navbar = () => {
  const user = useAppSelector(usersSelector.userData);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className="navbarContainer">
      <div>{isMobile && <MobileMenu />}</div>
      <div className="navbarLogoMiddle">
        <Link to="/">
          <img src="/images/logo.png" />
        </Link>
      </div>
      {user && !isMobile ? (
        <div className="navbarGadgets">
          <p style={{ marginBottom: 0, fontWeight: 500 }}>
            Bienvenido/a {user.socialReason}
          </p>
          <span className="navbarAccountGadget">
            <Link to="/perfil">
              <FaUserCircle color="black" />
            </Link>
          </span>
        </div>
      ) : null}
    </nav>
  );
};

export default Navbar;
