import axios from 'axios';
import {
  createAsyncThunk
} from '@reduxjs/toolkit';
import {
  errorSideAlert,
  successSideAlert,
  warningSideAlert
} from '../alerts';
import {
  saveAs
} from 'file-saver';
import { polizasStoreKey } from './polizas.const';


const polizasPath = `${process.env.REACT_APP_BACKEND_URL}/polizas`

export const createPoliza = createAsyncThunk(
  `${polizasStoreKey}/createPoliza`,
  async (formData, thunkApi) => {
    try {
      const response = await axios.post(`${polizasPath}/create`, formData);

      thunkApi.dispatch(successSideAlert("Póliza creada exitosamente!"));

      return response.data;
    } catch (error) {
      return thunkApi.dispatch(errorSideAlert(error.response.data.message));
    }
  }
);

export const getPolizas = createAsyncThunk(
  `${polizasStoreKey}/getPolizas`,
  async (query, thunkApi) => {
    try {
      const response = await axios.get(polizasPath + query);

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return null;
    }
  }
);

export const getPolizaDetail = createAsyncThunk(
  `${polizasStoreKey}/getPolizaDetail`,
  async (id, thunkApi) => {
    try {
      const response = await axios.get(`${polizasPath}/id/${id}`);

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return null;
    }
  }
);

export const simpleUpdatePoliza = createAsyncThunk(
  `${polizasStoreKey}/simpleUpdatePoliza`,
  async (poliza, thunkApi) => {
    try {
      const response = await axios.put(
        `${polizasPath}/simple-update/id/${poliza._id}`, {
        anulled: !poliza.anulled
      }
      );

      if (!poliza.anulled) thunkApi.dispatch(warningSideAlert("Póliza anulada"));
      else thunkApi.dispatch(successSideAlert("Póliza activada"));

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return null;
    }
  }
);

export const complexUpdatePoliza = createAsyncThunk(
  `${polizasStoreKey}/complexUpdatePoliza`,
  async ({ pid, formData }, thunkApi) => {
    try {
      const response = await axios.put(
        `${polizasPath}/id/${pid}`, formData
      );

      thunkApi.dispatch(successSideAlert("Póliza actualizada exitosamente!"));

      return response.data;
    } catch (error) {
      thunkApi.dispatch(errorSideAlert(error.response.data.message));
      return null;
    }
  }
);

export const downloadPolizasReport = createAsyncThunk(
  `${polizasStoreKey}/downloadPolizasReport`,
  async ({
    from,
    to
  }, thunkApi) => {
    try {
      const response = await axios.get(
        `${polizasPath}/to-expire?from=${from}&to=${to}`, {
        responseType: 'blob'
      },
      );

      const contentDisposition = response.headers['content-disposition'];
      if (contentDisposition) {
        const filename = contentDisposition.split('filename=')[1];
        saveAs(new Blob([response.data]), filename);
        thunkApi.dispatch(successSideAlert("Reporte descargado"));
      }
      return;
    } catch (error) {
      return thunkApi.dispatch(errorSideAlert(error.response.data.message));
    }
  }
);