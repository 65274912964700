import {
  createSlice
} from "@reduxjs/toolkit";
import {
  getSiniestroDetail,
  getSiniestros,
  siniestrosStoreKey
} from ".";

const initialState = {
  isLoading: false,
  siniestrosData: null,
};

export const siniestrosSlice = createSlice({
  name: siniestrosStoreKey,
  initialState,
  reducers: {},

  extraReducers(builder) {
    // getSiniestros
    builder.addCase(getSiniestros.pending, (state) => {
      state.isLoading = true;
      state.siniestrosData = null;
    });
    builder.addCase(getSiniestros.rejected, (state) => {
      state.isLoading = false;
      state.siniestrosData = null;
    });
    builder.addCase(getSiniestros.fulfilled, (state, action) => {
      state.isLoading = false;
      state.siniestrosData = action.payload;
    });
    // getSiniestroDetail
    builder.addCase(getSiniestroDetail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSiniestroDetail.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSiniestroDetail.fulfilled, (state) => {
      state.isLoading = false;
    });
  }
});

// export const { setsiniestrosData } = siniestrosSlice.actions;