import '../Stats.css';
import { insuranceTypes } from '../../../constants';
import Spinner from '../../../components/Spinner/Spinner';

const twoMonthsAgo = new Date();
twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

const calculatePercentageChange = (oldValue, newValue) => {
  let variation = 0
  if (oldValue !== 0) {
    const change = ((newValue - oldValue) / oldValue) * 100;
    variation = parseFloat(change.toFixed(1))
  } else {
    newValue === 0 ? variation = 0 : variation = 100;
  }
  return (
    <span style={
      variation > 0 ? { color: 'green' } : variation === 0 ? {} : { color: 'red' }
    }>{variation > 0 ? `+${variation}` : variation}%</span>
  );
};

export const StatsPolizasTypes = ({polizas}) => {

  return (
    <div style={{ width: "95%" }}>
      {!polizas.length
        ? <Spinner text='Preparando los datos...' />
        :
        <>
          <p className="mb-0 mt-2 text-center">
            Las estadísticas están basadas en pólizas vigentes
          </p>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Tipo de póliza</th>
                <th scope="col">Altas mes anterior</th>
                <th scope="col">Altas ultimos 30 días</th>
                <th scope="col">Variación %</th>
                <th scope="col">Totales vigentes</th>

              </tr>
            </thead>
            <tbody>
              {insuranceTypes.map((type, index) => {
                return (
                  <tr key={index}>
                    <td> {type.label}</td>
                    <td >{polizas.filter(poliza =>
                      poliza.polizaType === type.value && new Date(poliza.createdAt) > twoMonthsAgo && new Date(poliza.createdAt) < oneMonthAgo
                    ).length}</td>
                    <td >{polizas.filter(poliza =>
                      poliza.polizaType === type.value && new Date(poliza.createdAt) > oneMonthAgo && new Date(poliza.createdAt) < new Date()
                    ).length}</td>
                    <td>
                      {calculatePercentageChange(
                        polizas.filter(poliza =>
                          poliza.polizaType === type.value && new Date(poliza.createdAt) > twoMonthsAgo && new Date(poliza.createdAt) < oneMonthAgo
                        ).length,
                        polizas.filter(poliza =>
                          poliza.polizaType === type.value && new Date(poliza.createdAt) > oneMonthAgo && new Date(poliza.createdAt) < new Date()
                        ).length
                      )}

                    </td>
                    <td>
                      {polizas.filter(poliza =>
                        poliza.polizaType === type.value
                      ).length}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table >
        </>}
    </div >
  )
}