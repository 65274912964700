import styles from '../ReportSiniestro.module.css';

const ThirdInvolved = (props) => {
  const handleOnChange = (e) => {
    props.handleOnChangeTercero(e);
  };

  return (
    <>
      <h5 className="text-center mb-0 mt-4">Datos del tercero</h5>
      <label>Nombre completo del tercero involucrado</label>
      <input
        placeholder="Nombre completo"
        className="form-control"
        name="socialReason"
        onChange={handleOnChange}
        type="text"
      />
      <div className={styles.doubleInputWrapper}>
        <div className={styles.labelAndInputWrapper}>
          <label>Tipo de documento del tercero</label>
          <select
            className="form-select"
            name="documentType"
            onChange={handleOnChange}
          >
            <option value="" hidden>
              Tipo de documento
            </option>
            <option value="CUIT">CUIT</option>
            <option value="DNI">DNI</option>
          </select>
        </div>
        <div className={styles.labelAndInputWrapper}>
          <label>Número de documento</label>
          <input
            placeholder="Número de documento"
            className="form-control"
            name="documentNumber"
            onChange={handleOnChange}
            type="number"
          />
        </div>
      </div>
      <label>Compañía de seguro del tercero</label>
      <input
        placeholder="Compañía de seguro"
        className="form-control"
        name="insuranceCompany"
        onChange={handleOnChange}
        type="text"
      />
      <div className={styles.doubleInputWrapper}>
        <div className={styles.labelAndInputWrapper}>
          <label>Nº de Póliza del tercero</label>
          <input
            placeholder="Numero de póliza"
            className="form-control"
            name="polizaNumber"
            onChange={handleOnChange}
            type="text"
          />
        </div>
        <div className={styles.labelAndInputWrapper}>
          <label>Nº de Patente del tercero</label>
          <input
            placeholder="Patente"
            className="form-control"
            name="domain"
            onChange={handleOnChange}
          />
        </div>
      </div>
      <label className="mt-3">
        Subí acá fotos/documentos relacionadas al <strong>TERCERO</strong>
      </label>
      <input className="form-control" type="file" name="thirdFiles" multiple />
      <div className="form-text">
        Cargue los archivos del tercero: (Cedula del vehículo, registro del
        tercero, constancia del seguro, fotos del vehículo de los daños,
        constancia de cbu (robo ó incendio total.
      </div>
    </>
  );
};

export default ThirdInvolved;
