import {
  createSlice
} from '@reduxjs/toolkit';
import {
  essentialsStoreKey,
  getInsuranceCompanies,
  getInsuranceTypes,
} from '.';


const initialState = {
  insuranceCompanies: [],
  insuranceTypes: [],
};

export const essentialsSlice = createSlice({
  name: essentialsStoreKey,
  initialState,
  reducers: {},
  extraReducers(builder) {
    // getInsuranceCompanies
    builder.addCase(getInsuranceCompanies.fulfilled, (state, action) => {
      state.insuranceCompanies = action.payload;
    });
    // getInsuranceTypes
    builder.addCase(getInsuranceTypes.fulfilled, (state, action) => {
      state.insuranceTypes = action.payload;
    });
  }
});